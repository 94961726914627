/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// NewUser page components
import MDDropzone from "components/MDDropzone";
import { useRef } from "react";

function ImagesForm({ formData, lang, options = { addRemoveLinks: true } }) {
  const { formField, values, errors, setFieldValue, setFieldTouched } =
    formData;
  const { gallery } = formField;
  const { gallery: galleryV } = values;
  const dropzoneRef = useRef();
  const getAcceptedFiles = (files) => {
    setFieldValue(gallery.name, files);
    setFieldTouched(gallery.name, files);
  };
  return (
    <MDBox>
      <MDTypography variant="h5" fontWeight="bold">
        {lang?.name}
      </MDTypography>
      <MDDropzone
        getAcceptedFiles={getAcceptedFiles}
        ref={dropzoneRef}
        type={gallery.type}
        label={gallery.label}
        name={gallery.name}
        value={galleryV}
        placeholder={gallery.placeholder}
        error={errors.gallery && touched.gallery}
        success={galleryV?.length > 0 && !errors.gallery}
      />
    </MDBox>
  );
}

// typechecking props for ImagesForm
ImagesForm.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default ImagesForm;
