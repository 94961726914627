/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
//  name, summary, desc, report_desc, result_video, report_file, promo_video;
const form = {
  formId: "new-tag-form",
  formField: {
    ar_name: {
      name: "ar_name",
      label: "الاسم",
      type: "text",
      errorMsg: "إلزامي",
    },
    en_name: {
      name: "en_name",
      label: "الاسم",
      type: "text",
      errorMsg: "إلزامي",
    },
    tr_name: {
      name: "tr_name",
      label: "الاسم",
      type: "text",
      errorMsg: "إلزامي",
    },
    ar_summary: {
      name: "ar_summary",
      label: "ملخص",
      type: "text",
      errorMsg: "إلزامي",
    },
    en_summary: {
      name: "en_summary",
      label: "ملخص",
      type: "text",
      errorMsg: "إلزامي",
    },
    tr_summary: {
      name: "tr_summary",
      label: "ملخص",
      type: "text",
      errorMsg: "إلزامي",
    },
    ar_desc: {
      name: "ar_desc",
      label: "التفاصيل",
      type: "text",
      errorMsg: "إلزامي",
    },
    en_desc: {
      name: "en_desc",
      label: "التفاصيل",
      type: "text",
      errorMsg: "إلزامي",
    },
    tr_desc: {
      name: "tr_desc",
      label: "التفاصيل",
      type: "text",
      errorMsg: "إلزامي",
    },
    ar_promo_video_url: {
      name: "ar_promo_video_url",
      label: "الفيديو الترويجي",
      type: "text",
      errorMsg: "إلزامي",
    },
    en_promo_video_url: {
      name: "en_promo_video_url",
      label: "الفيديو الترويجي",
      type: "text",
      errorMsg: "إلزامي",
    },
    tr_promo_video_url: {
      name: "tr_promo_video_url",
      label: "الفيديو الترويجي",
      type: "text",
      errorMsg: "إلزامي",
    },
    category_id: {
      name: "category_id",
      label: "التصنيف",
      type: "text",
      errorMsg: "إلزامي",
    },
    cost: {
      name: "cost",
      label: "القيمة",
      type: "number",
      errorMsg: "إلزامي",
    },
    benficial_num: {
      name: "benficial_num",
      label: "عدد المستفيدين",
      type: "number",
      errorMsg: "إلزامي",
    },
    impl_country_id: {
      name: "impl_country_id",
      label: "بلد التنفيذ",
      type: "text",
      errorMsg: "إلزامي",
    },
    period: {
      name: "period",
      label: "المدة",
      type: "number",
      errorMsg: "إلزامي",
    },
    period_type: {
      name: "period_type",
      label: "المدة",
      type: "text",
      errorMsg: "إلزامي",
    },
    status_id: {
      name: "status_id",
      label: "الحالة",
      type: "text",
      errorMsg: "إلزامي",
    },
    zakaat_available: {
      name: "zakaat_available",
      label: "تجوز الزكاة",
      type: "text",
      errorMsg: "إلزامي",
    },
    show_in_home: {
      name: "show_in_home",
      label: "العرض في الرئيسية",
      type: "text",
      errorMsg: "إلزامي",
    },
    all_amount_required: {
      name: "all_amount_required",
      label: "قيمة المشروع بالكامل مطلوبة(مشاريع خاصة)",
      type: "text",
      errorMsg: "إلزامي",
    },
    urgent: {
      name: "urgent",
      label: "عاجل",
      type: "text",
      errorMsg: "إلزامي",
    },
    time_line_items: {
      name: "time_line_items",
      label: "الخطة الزمنية",
      type: "text",
      errorMsg: "إلزامي",
    },
    target_segments: {
      name: "target_segments",
      label: "الفئة المستهدفة",
      type: "text",
      errorMsg: "إلزامي",
    },
    ar_targets: {
      name: "ar_targets",
      label: "الأهداف",
      type: "text",
      errorMsg: "إلزامي",
    },
    en_targets: {
      name: "en_targets",
      label: "الأهداف",
      type: "text",
      errorMsg: "إلزامي",
    },
    tr_targets: {
      name: "tr_targets",
      label: "الأهداف",
      type: "text",
      errorMsg: "إلزامي",
    },
    gallery: {
      name: "gallery",
      label: "المعرض",
      type: "text",
      errorMsg: "إلزامي",
    },
    ar_pic: {
      name: "ar_pic",
      label: "الصورة الرئيسية (عربي)",
      type: "text",
      errorMsg: "إلزامي",
    },
    en_pic: {
      name: "en_pic",
      label: "الصورة الرئيسية (إنجليزي)",
      type: "text",
      errorMsg: "إلزامي",
    },
    tr_pic: {
      name: "tr_pic",
      label: "الصورة الرئيسية (تركي)",
      type: "text",
      errorMsg: "إلزامي",
    },
  },
};

export default form;
