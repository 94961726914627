/**
=========================================================
* Arakan - v2.1.0
=========================================================

* Product Page: https://www.trakib.com/product/material-dashboard-pro-react
* Copyright 2022 Trakib (https://www.trakib.com)

Coded by www.trakib.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { InputLabel, Autocomplete } from "@mui/material";

// Arakan components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDAvatar from "components/MDAvatar";

// Arakan examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import FormField from "layouts/applications/wizard/components/FormField";
import { useNavigate } from "react-router-dom";

import CrudService from "services/cruds-service";
import AuthService from "services/auth-service";

import i18n from "i18n";

const AddUser = () => {
  const navigate = useNavigate();

  const { t } = i18n;
  const [roles, setRoles] = useState([]);
  const [fileState, setFileState] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [user, setUser] = useState({
    email: "",
    password: "",
    confirm: "",
    role: "",
    first_name: "",
    last_name: "",
  });

  const [value, setValue] = useState(null);
  const [isAdmin, setIsAdmin] = useState(null);

  const [error, setError] = useState({
    first_name: false,
    last_name: false,
    email: false,
    password: false,
    confirm: false,
    role: false,
    error: false,
    textError: "",
  });

  useEffect(() => {
    (async () => {
      try {
        const { data } = await AuthService.getProfile();
        setIsAdmin(data.role.name);
        if (data.role.name === "Administrator" || data.role.name === "admins") {
          const rolesRes = await CrudService.getRoles();
          setRoles(rolesRes.data);
        }
      } catch (err) {
        console.error(err);
        return null;
      }
    })();
  }, []);

  useEffect(() => {
    if (user.role !== "" && roles.length > 0) {
      const role = roles.find((role) => role.id === user.role);
      setValue(role);
    }
  }, [roles, user]);

  const changeHandler = (e) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  };

  const changeImageHandler = (e) => {
    const formData = new FormData();
    formData.append("attachment", e.target.files[0]);
    setFileState(formData);
    setImageUrl(URL.createObjectURL(e.target.files[0]));
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    const mailFormat = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (user.first_name.trim().length === 0) {
      setError({
        email: false,
        role: false,
        confirm: false,
        password: false,
        first_name: true,
        last_name: false,
        textError: t("required"),
      });
      return;
    }

    if (user.last_name.trim().length === 0) {
      setError({
        email: false,
        role: false,
        confirm: false,
        password: false,
        first_name: false,
        last_name: true,
        textError: t("required"),
      });
      return;
    }

    if (
      user.email.trim().length === 0 ||
      !user.email.trim().match(mailFormat)
    ) {
      setError({
        role: false,
        confirm: false,
        password: false,
        first_name: false,
        last_name: false,
        email: true,
        textError: t("required"),
      });
      return;
    }

    if (user.password.trim().length < 8) {
      setError({
        first_name: false,
        last_name: false,
        email: false,
        role: false,
        confirm: false,
        password: true,
        textError: t("password8chr"),
      });
      return;
    }

    if (user.confirm.trim() !== user.password.trim()) {
      setError({
        first_name: false,
        last_name: false,
        email: false,
        role: false,
        password: false,
        confirm: true,
        textError: t("passwordNotMatch"),
      });
      return;
    }

    if (
      value.id === "" &&
      (isAdmin === "Administrator" || isAdmin === "admins")
    ) {
      setError({
        first_name: false,
        last_name: false,
        email: false,
        role: false,
        password: false,
        confirm: false,
        role: true,
        textError: t("required"),
      });
      return;
    }

    const newUser = {
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
      password: user.password,
    };

    newUser["role"] = value.id ? value.id.toString() : user.role.toString();

    let res = null;
    try {
      res = await CrudService.createUser(newUser);
    } catch (err) {
      setError({ ...error, error: true, textError: err.message });
      return null;
    }
    if (res) {
      try {
        if (fileState) {
          let data = await CrudService.imageUpload(fileState, res.data.id).id;
          const toUpdateUser = {
            avatar: data,
          };
          data && (await CrudService.updateUser(toUpdateUser, res.data.id));
        }

        navigate("/userManagement/employees", {
          state: { value: true, text: t("createSuccessfully") },
        });
      } catch (err) {
        if (err.hasOwnProperty("errors")) {
          setError({ ...error, error: true, textError: err.errors[0].detail });
        }
        return null;
      }
    }
  };

  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      <MDBox mt={5} mb={9}>
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={8}>
            <MDBox mt={6} mb={8} textAlign="center">
              <MDBox mb={1}>
                <MDTypography variant="h3" fontWeight="bold">
                  {t("addUser")}
                </MDTypography>
              </MDBox>
              {/* <MDTypography variant="h5" fontWeight="regular" color="secondary">
                This information will describe more about the user.
              </MDTypography> */}
            </MDBox>
            <Card>
              <MDBox
                component="form"
                method="POST"
                onSubmit={submitHandler}
                encType="multipart/form-data"
              >
                <MDBox display="flex" flexDirection="column" px={3} my={4}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <FormField
                        label={t("firstName")}
                        placeholder="Alec"
                        name="first_name"
                        value={user.first_name}
                        onChange={changeHandler}
                        error={error.first_name}
                      />
                      {error.first_name && (
                        <MDTypography
                          variant="caption"
                          color="error"
                          fontWeight="light"
                        >
                          {error.textError}
                        </MDTypography>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormField
                        label={t("lastName")}
                        placeholder="Alec"
                        name="last_name"
                        value={user.last_name}
                        onChange={changeHandler}
                        error={error.last_name}
                      />
                      {error.last_name && (
                        <MDTypography
                          variant="caption"
                          color="error"
                          fontWeight="light"
                        >
                          {error.textError}
                        </MDTypography>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormField
                        label={t("email")}
                        placeholder="example@email.com"
                        inputProps={{
                          type: "email",
                          autoComplete: "email",
                          form: {
                            autoComplete: "off",
                          },
                        }}
                        name="email"
                        value={user.email}
                        onChange={changeHandler}
                        error={error.email}
                      />
                      {error.email && (
                        <MDTypography
                          variant="caption"
                          color="error"
                          fontWeight="light"
                        >
                          {error.textError}
                        </MDTypography>
                      )}
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} mt={4}>
                    <Grid item xs={12} sm={6}>
                      <MDInput
                        label={t("currentPassword")}
                        inputProps={{ type: "password", autoComplete: "" }}
                        name="password"
                        value={user.password}
                        onChange={changeHandler}
                        error={error.password}
                      />
                      {error.password && (
                        <MDTypography
                          variant="caption"
                          color="error"
                          fontWeight="light"
                        >
                          {error.textError}
                        </MDTypography>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <MDInput
                        label={t("confirmPassword")}
                        inputProps={{ type: "password", autoComplete: "" }}
                        name="confirm"
                        value={user.confirm}
                        onChange={changeHandler}
                        error={error.confirm}
                      />
                      {error.confirm && (
                        <MDTypography
                          variant="caption"
                          color="error"
                          fontWeight="light"
                        >
                          {error.textError}
                        </MDTypography>
                      )}
                    </Grid>
                  </Grid>
                  <MDBox display="flex" flexDirection="column">
                    {(isAdmin === "Administrator" || isAdmin === "admins") && (
                      <MDBox
                        display="flex"
                        flexDirection="column"
                        marginTop="2rem"
                      >
                        <Autocomplete
                          defaultValue={null}
                          options={roles}
                          getOptionLabel={(option) => {
                            if (option.data) {
                              if (option.data.name) return option.data.name;
                            } else {
                              if (option) {
                                if (option.name) return option.name;
                              }
                            }
                            return "";
                          }}
                          value={value}
                          isOptionEqualToValue={(option, item) =>
                            option === item || option.id === item.id
                          }
                          onChange={(event, newValue) => {
                            setValue(newValue);
                          }}
                          renderInput={(params) => (
                            <FormField
                              {...params}
                              label={t("role")}
                              InputLabelProps={{ shrink: true }}
                            />
                          )}
                        />
                        {error.role && (
                          <MDTypography
                            variant="caption"
                            color="error"
                            fontWeight="light"
                            pl={4}
                          >
                            {error.textError}
                          </MDTypography>
                        )}
                      </MDBox>
                    )}
                    <MDBox
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <MDBox mt={2} display="flex" flexDirection="column">
                        <InputLabel id="demo-simple-select-label">
                          {t("profileImage")}
                        </InputLabel>
                        <MDInput
                          type="file"
                          name="attachment"
                          onChange={changeImageHandler}
                          id="file-input"
                          accept="image/*"
                          sx={{ mt: "16px" }}
                        ></MDInput>
                      </MDBox>

                      {imageUrl && (
                        <MDBox ml={4} mt={2}>
                          <MDAvatar
                            src={imageUrl}
                            alt="profile-image"
                            size="xxl"
                            shadow="sm"
                          />
                        </MDBox>
                      )}
                    </MDBox>
                  </MDBox>
                  {error.error && (
                    <MDTypography
                      variant="caption"
                      color="error"
                      fontWeight="light"
                      pt={2}
                    >
                      {error.textError}
                    </MDTypography>
                  )}
                  <MDBox
                    ml="auto"
                    mt={4}
                    mb={2}
                    display="flex"
                    justifyContent="flex-end"
                  >
                    <MDBox mx={2}>
                      <MDButton
                        variant="gradient"
                        color="dark"
                        size="small"
                        px={2}
                        mx={2}
                        onClick={() =>
                          navigate("/userManagement/employees", {
                            state: { value: false, text: "" },
                          })
                        }
                      >
                        {t("back")}
                      </MDButton>
                    </MDBox>
                    <MDButton
                      variant="gradient"
                      color="dark"
                      size="small"
                      type="submit"
                    >
                      {t("save")}
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default AddUser;
