/**
=========================================================
* Arakan - v2.1.0
=========================================================

* Product Page: https://www.trakib.com/product/material-dashboard-pro-react
* Copyright 2022 Trakib (https://www.trakib.com)

Coded by www.trakib.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// @material-ui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Arakan components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDAlert from "components/MDAlert";

// Settings page components

import crudsService from "services/cruds-service";
import { Autocomplete } from "@mui/material";
import MDInput from "components/MDInput";
import i18n from "i18n";
import FormField from "layouts/pages/account/components/FormField";

function MainInfo({ pages, slider }) {
  const [page, setPage] = useState(null);
  const { t } = i18n;
  console.log(slider);
  const [sequence, setSequence] = useState(slider.sequence);
  const [notification, setNotification] = useState({
    value: false,
    color: "info",
    message: "",
  });

  useEffect(() => {
    if (slider.page_id !== "" && pages.length > 0) {
      const page_id = pages.find((page_id) => page_id.id === slider.page_id);
      setPage(page_id);
    }
  }, [pages, slider]);

  useEffect(() => {
    if (notification.value === true) {
      setTimeout(() => {
        setNotification({ value: false, color: "info", message: "" });
      }, 5000);
    }
  }, [notification]);

  const [errors, setErrors] = useState({
    page_id: false,
    sequence: false,
  });

  const submitHandler = async (e) => {
    e.preventDefault();

    if (!page) {
      setNotification({
        value: true,
        message: "لم تختار الصفحة",
        color: "error",
      });
      return;
    }

    const sliderData = {
      page_id: page.id,
      sequence: sequence,
    };
    // call api for update
    await crudsService.updateSliderInfo(sliderData, slider.id);

    // reset errors
    setErrors({
      page_id: false,
    });

    setNotification({
      value: true,
      color: "info",
      message: "Your profile has been updated",
    });
  };

  return (
    <>
      <Card id="basic-info" sx={{ overflow: "visible" }}>
        <MDBox p={3}>
          <MDTypography variant="h5">بيانات الشريحة</MDTypography>
        </MDBox>
        <MDBox
          component="form"
          pb={3}
          px={3}
          method="POST"
          onSubmit={submitHandler}
        >
          <Grid container spacing={3}>
            {/* <Grid item xs={6}>
              <Autocomplete
                options={pages}
                name="page_id"
                renderOption={(props, option) => (
                  <li {...props} key={option.id}>
                    {option.data ? option.data.name : option.name}
                  </li>
                )}
                getOptionLabel={(option) => {
                  if (option.data) {
                    if (option.data.name) return option.data.name;
                  } else {
                    if (option) {
                      if (option.name) return option.name;
                    }
                  }
                  return "";
                }}
                isOptionEqualToValue={(option, item) =>
                  option === item || option?.id === item?.id
                }
                variant="outlined"
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    //variant="standard"
                    label="الصفحة"
                    error={errors.page_id}
                  />
                )}
                onChange={(e, v) => setPage(v)}
                value={page}
              />
            </Grid> */}
            <Grid item xs={12}>
              <FormField
                variant="outlined"
                onChange={(e) => setSequence(e.target.value)}
                label="الترتيب"
                name="sequence"
                value={sequence}
                placeholder={"sequence"}
                error={errors.sequence}
              />
            </Grid>
          </Grid>
          <MDBox ml="auto" mt={2} display="flex" justifyContent="flex-end">
            <MDButton
              variant="gradient"
              color="dark"
              size="small"
              type="submit"
            >
              {t("update")}
            </MDButton>
          </MDBox>
        </MDBox>
      </Card>
      {notification.value === true && (
        <MDAlert color={notification.color} mt="20px">
          <MDTypography variant="body2" color="white">
            {notification.message}
          </MDTypography>
        </MDAlert>
      )}
    </>
  );
}

export default MainInfo;
