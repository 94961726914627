/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
//  name, summary, description, report_description, result_video, report_file, promo_video;

//summary

const form = {
  formId: "new-tag-form",
  formField: {
    ar_name: {
      name: "ar_name",
      label: "الاسم",
      type: "text",
      errorMsg: "إلزامي",
    },
    en_name: {
      name: "en_name",
      label: "الاسم",
      type: "text",
      errorMsg: "إلزامي",
    },
    tr_name: {
      name: "tr_name",
      label: "الاسم",
      type: "text",
      errorMsg: "إلزامي",
    },
    ar_description: {
      name: "ar_description",
      label: "التفاصيل",
      type: "text",
      errorMsg: "إلزامي",
    },
    en_description: {
      name: "en_description",
      label: "التفاصيل",
      type: "text",
      errorMsg: "إلزامي",
    },
    tr_description: {
      name: "tr_description",
      label: "التفاصيل",
      type: "text",
      errorMsg: "إلزامي",
    },
    ar_summary: {
      name: "ar_summary",
      label: "الملخص",
      type: "text",
      errorMsg: "إلزامي",
    },
    en_summary: {
      name: "en_summary",
      label: "الملخص",
      type: "text",
      errorMsg: "إلزامي",
    },
    tr_summary: {
      name: "tr_summary",
      label: "الملخص",
      type: "text",
      errorMsg: "إلزامي",
    },
    author_id: {
      name: "author_id",
      label: "المؤلف",
      type: "text",
      errorMsg: "إلزامي",
    },
    date: {
      name: "date",
      label: "تاريخ المقال",
      type: "date",
      errorMsg: "إلزامي",
    },
    tags_id: {
      name: "tags_id",
      label: "الوسوم",
      type: "text",
      errorMsg: "إلزامي",
    },
    // gallery: {
    //   name: "gallery",
    //   label: "المعرض",
    //   type: "text",
    //   errorMsg: "إلزامي",
    // },
    ar_pic: {
      name: "ar_pic",
      label: "الصورة الرئيسية (عربي)",
      type: "text",
      errorMsg: "إلزامي",
    },
    en_pic: {
      name: "en_pic",
      label: "الصورة الرئيسية (إنجليزي)",
      type: "text",
      errorMsg: "إلزامي",
    },
    tr_pic: {
      name: "tr_pic",
      label: "الصورة الرئيسية (تركي)",
      type: "text",
      errorMsg: "إلزامي",
    },
  },
};

export default form;
