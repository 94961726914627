/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import checkout from "./form";

const {
  formField: {
    ar_name,
    ar_summary,
    ar_desc,
    ar_promo_video_url,
    ar_targets,
    ar_pic,
    en_name,
    en_summary,
    en_desc,
    en_promo_video_url,
    tr_name,
    tr_summary,
    tr_desc,
    tr_promo_video_url,
    category_id,
    cost,
    benficial_num,
    impl_country_id,
    period,
    period_type,
    status_id,
    zakaat_available,
    show_in_home,
    all_amount_required,
    urgent,
    time_line_items,
    target_segments,
    en_targets,
    tr_targets,
    gallery,
    en_pic,
    tr_pic,
    // ar_report_desc,
    // ar_result_video,
    // ar_report_file,
    // ar_promo_video,
    // en_report_desc,
    // en_result_video,
    // en_report_file,
    // en_promo_video,
    // tr_report_desc,
    // tr_result_video,
    // tr_report_file,
    // tr_promo_video,
  },
} = checkout;

const initialValues = {
  [ar_name.name]: "",
  [en_name.name]: "",
  [tr_name.name]: "",
  [ar_summary.name]: "",
  [en_summary.name]: "",
  [tr_summary.name]: "",
  [ar_desc.name]: "",
  [en_desc.name]: "",
  [tr_desc.name]: "",
  [ar_promo_video_url.name]: "",
  [en_promo_video_url.name]: "",
  [tr_promo_video_url.name]: "",
  [category_id.name]: null,
  [cost.name]: "",
  [benficial_num.name]: "",
  [impl_country_id.name]: null,
  [period.name]: "",
  [period_type.name]: null,
  [status_id.name]: null,
  [zakaat_available.name]: false,
  [show_in_home.name]: false,
  [all_amount_required.name]: false,
  [urgent.name]: false,
  [time_line_items.name]: [],
  [target_segments.name]: [],
  [ar_targets.name]: "",
  [en_targets.name]: "",
  [tr_targets.name]: "",
  [gallery.name]: [],
  [ar_pic.name]: null,
  [en_pic.name]: null,
  [tr_pic.name]: null,
  // [ar_report_desc.name]: "",
  // [en_report_desc.name]: "",
  // [tr_report_desc.name]: "",
  // [ar_result_video.name]: null,
  // [en_result_video.name]: null,
  // [tr_result_video.name]: null,
  // [ar_report_file.name]: null,
  // [en_report_file.name]: null,
  // [tr_report_file.name]: null,
  // [ar_promo_video.name]: null,
  // [en_promo_video.name]: null,
  // [tr_promo_video.name]: null,
};

export default initialValues;
