/**
=========================================================
* Arakan - v2.1.0
=========================================================

* Product Page: https://www.trakib.com/product/material-dashboard-pro-react
* Copyright 2022 Trakib (https://www.trakib.com)

Coded by www.trakib.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useMemo } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Arakan components
import MDBox from "components/MDBox";

// Settings page components
import Header from "./components/Header";
import MainInfo from "./components/MainInfo";
import LangForm from "./components/LangForm";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import Footer from "examples/Footer";
import crudsService from "services/cruds-service";
import { useNavigate, useParams } from "react-router-dom";

function EditSlider() {
  const { id } = useParams();
  const [slider, setSlider] = useState({
    id: null,
    page_id: null,
  });
  const [translations, setTranslations] = useState([]);
  const [pages, setPages] = useState([]);

  // const prepareNewArr = (arr, objKey) => {
  //   let newArr = [];
  //   for (const key in arr) {
  //     if (Object.hasOwnProperty.call(arr, key)) {
  //       const element = arr[key];
  //       newArr.push({
  //         id: element.id,
  //         name: element.name,
  //         code: element.code,
  //       });
  //     }
  //   }
  //   return newArr;
  // };

  const getSliders = async () => {
    const response = await crudsService.getSliderById(id);
    setSlider((prevSlider) => ({
      ...prevSlider,
      id: response.data.id,
      page_id: response.data.page_id,
      sequence: response.data.sequence,
    }));
    setTranslations(response.data.translations);
  };

  useEffect(() => {
    (async () => {
      if (!id) return;
      getSliders();
      try {
        const pagesData = await crudsService.getPages();
        setPages(pagesData.data);
      } catch (err) {
        console.error(err);
        return null;
      }
    })();
  }, [id]);

  const langForm = useMemo(
    () =>
      translations.map((t) => (
        <Grid
          container
          spacing={3}
          py={2}
          key={Math.random()}
          id={`${t.languages_code?.code.split("-")[0]}-info`}
        >
          <Grid item xl={4}>
            <Header slider={t} data={t.image} />
          </Grid>
          <Grid item xl={8}>
            <LangForm slider={t} id={t.id} />
          </Grid>
        </Grid>
      )),
    [translations]
  );

  const getMainInfo = useMemo(() => {
    if (pages.length > 0 && slider)
      return <MainInfo slider={slider} pages={pages} />;
  }, [pages, slider]);

  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      <MDBox m={4}>
        <MDBox mb={3}>
          <Grid container spacing={3} justifyContent="center">
            <Grid item xl={12} lg={9}>
              <MDBox mb={3}>
                {langForm}
                <Grid container spacing={3}>
                  <Grid item xl={12}>
                    {getMainInfo}
                  </Grid>
                </Grid>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default EditSlider;
