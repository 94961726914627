/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
//  name, summary, desc, report_desc, result_video, report_file, promo_video;
const form = {
  formId: "new-tag-form",
  formField: {
    ar_name: {
      name: "ar_name",
      label: "الاسم",
      type: "text",
      errorMsg: "إلزامي",
    },
    en_name: {
      name: "en_name",
      label: "الاسم",
      type: "text",
      errorMsg: "إلزامي",
    },
    tr_name: {
      name: "tr_name",
      label: "الاسم",
      type: "text",
      errorMsg: "إلزامي",
    },
    ar_desc: {
      name: "ar_desc",
      label: "التفاصيل",
      type: "text",
      errorMsg: "إلزامي",
    },
    en_desc: {
      name: "en_desc",
      label: "التفاصيل",
      type: "text",
      errorMsg: "إلزامي",
    },
    tr_desc: {
      name: "tr_desc",
      label: "التفاصيل",
      type: "text",
      errorMsg: "إلزامي",
    },
    page_id: {
      name: "page_id",
      label: "الصفحة",
      type: "text",
      errorMsg: "إلزامي",
    },
    ar_pic: {
      name: "ar_pic",
      label: "الصورة الرئيسية (عربي)",
      type: "text",
      errorMsg: "إلزامي",
    },
    en_pic: {
      name: "en_pic",
      label: "الصورة الرئيسية (إنجليزي)",
      type: "text",
      errorMsg: "إلزامي",
    },
    tr_pic: {
      name: "tr_pic",
      label: "الصورة الرئيسية (تركي)",
      type: "text",
      errorMsg: "إلزامي",
    },
  },
};

export default form;
