/**
=========================================================
* Arakan - v2.1.0
=========================================================

* Product Page: https://www.trakib.com/product/material-dashboard-pro-react
* Copyright 2022 Trakib (https://www.trakib.com)

Coded by www.trakib.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// @material-ui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Arakan components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDAlert from "components/MDAlert";

// Settings page components
import FormField from "layouts/pages/account/components/FormField";

import crudsService from "services/cruds-service";
// import { Autocomplete, Switch } from "@mui/material";
// import MDInput from "components/MDInput";
import i18n from "i18n";

function MainInfo({ project, id, categories }) {
  // const [category, setCategory] = useState([]);
  const { t } = i18n;

  const [info, setInfo] = useState({
    cost: 0,
    sequence: 0,
    // category_id: null,
  });
  const [notification, setNotification] = useState({
    value: false,
    color: "info",
    message: "",
  });

  useEffect(() => {
    setInfo({
      cost: project.cost,
      sequence: project.sequence,
    });
  }, [project]);

  // useEffect(() => {
  //   if (project.category !== "" && categories.length > 0) {
  //     const category_id = categories.find(
  //       (category_id) => category_id.id === project.category.id
  //     );
  //     setCategory(category_id);
  //   }
  // }, [categories, project]);

  useEffect(() => {
    if (notification.value === true) {
      setTimeout(() => {
        setNotification({ value: false, color: "info", message: "" });
      }, 5000);
    }
  }, [notification]);

  const [errors, setErrors] = useState({
    cost: false,
    sequence: false,
    // category_id: false,
  });

  const changeHandler = (e) => {
    setInfo({
      ...info,
      [e.target.name]: e.target.value,
    });
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    // if (!category) {
    //   setNotification({
    //     value: true,
    //     message: "لم تختار التصنيف",
    //     color: "error",
    //   });
    //   return;
    // }
    if (!info.cost) {
      setNotification({
        value: true,
        message: "لم تدخل التكلفة",
        color: "error",
      });
      return;
    }

    const projectData = {
      cost: info.cost,
      sequence: info.sequence,
      // category_id: category.id,
    };
    // call api for update
    await crudsService.updateProjectInfo(projectData, project.id);

    // reset errors
    setErrors({
      firstNameError: false,
      lastNameError: false,
      emailError: false,
    });

    setNotification({
      value: true,
      color: "info",
      message: "Your profile has been updated",
    });
  };

  return (
    <>
      <Card id="basic-info" sx={{ overflow: "visible" }}>
        <MDBox p={3}>
          <MDTypography variant="h5">بيانات المشروع</MDTypography>
        </MDBox>
        <MDBox
          component="form"
          pb={3}
          px={3}
          method="POST"
          onSubmit={submitHandler}
        >
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <FormField
                onChange={changeHandler}
                label={t("cost")}
                name="cost"
                value={info.cost}
                placeholder="cost"
                error={errors.cost}
              />
            </Grid>

            <Grid item xs={6}>
              <FormField
                onChange={changeHandler}
                label="الترتيب"
                name="sequence"
                value={info.sequence}
                placeholder={"sequence"}
                error={errors.sequence}
              />
            </Grid>

            {/* <Grid item xs={3}>
              <Autocomplete
                options={categories}
                name="category_id"
                renderOption={(props, option) => (
                  <li {...props} key={option.id}>
                    {option.data ? option.data.name : option.name}
                  </li>
                )}
                getOptionLabel={(option) => {
                  if (option.data) {
                    if (option.data.name) return option.data.name;
                  } else {
                    if (option) {
                      if (option.name) return option.name;
                    }
                  }
                  return "";
                }}
                isOptionEqualToValue={(option, item) =>
                  option === item || option?.id === item?.id
                }
                variant="outlined"
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    //variant="standard"
                    label="التصنيف"
                    error={errors.category_id}
                  />
                )}
                onChange={(e, v) => setCategory(v)}
                value={category}
              />
            </Grid> */}
          </Grid>
          <MDBox ml="auto" mt={2} display="flex" justifyContent="flex-end">
            <MDButton
              variant="gradient"
              color="dark"
              size="small"
              type="submit"
            >
              {t("update")}
            </MDButton>
          </MDBox>
        </MDBox>
      </Card>
      {notification.value === true && (
        <MDAlert color={notification.color} mt="20px">
          <MDTypography variant="body2" color="white">
            {notification.message}
          </MDTypography>
        </MDAlert>
      )}
    </>
  );
}

export default MainInfo;
