/**
=========================================================
* Arakan - v2.1.0
=========================================================

* Product Page: https://www.trakib.com/product/material-dashboard-pro-react
* Copyright 2022 Trakib (https://www.trakib.com)

Coded by www.trakib.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Arakan are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Arakan layouts
import Analytics from "layouts/dashboards/analytics";

import UserProfile from "cruds/user-profile";
import Tags from "cruds/article-management/settings/tag";
// import UserManagement from "cruds/user-management";
import EmployeesManagement from "cruds/user-management/employees";
import CustomersManagement from "cruds/user-management/custumers";
import Projects from "cruds/project-management/projects";
import Articles from "cruds/article-management/articles";
import Sliders from "cruds/website-management/sliders";
import Settings from "cruds/website-management/settings";
import Items from "cruds/project-management/settings/item";
import ArticleItems from "cruds/article-management/settings/item";
import Categories from "cruds/project-management/settings/category";
import TimeLine from "cruds/project-management/settings/timeline";
import EditCalendarOutlinedIcon from "@mui/icons-material/EditCalendarOutlined";
import GroupAddOutlinedIcon from "@mui/icons-material/GroupAddOutlined";
import SpecialProjects from "cruds/project-management/special-projects";

// Arakan components
import MDAvatar from "components/MDAvatar";

// @mui icons
import Icon from "@mui/material/Icon";

// Images
import profilePicture from "assets/images/team-3.jpg";

import i18n from "i18n";

const { t } = i18n;

const routes = [
  {
    type: "collapse",
    name: "Bruce Mars",
    key: "user-name",
    icon: <MDAvatar src={profilePicture} alt="Bruce Mars" size="sm" />,
    collapse: [
      {
        name: t("profile"),
        key: "user-profile",
        route: "/examples-api/user-profile",
        component: <UserProfile />,
      },
      {
        name: "Logout",
        key: "logout",
      },
    ],
  },
  { type: "divider", key: "divider-0" },
  {
    type: "collapse",
    name: t("dashboards"),
    key: "dashboards",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    collapse: [
      {
        name: t("analytics"),
        key: "analytics",
        route: "/dashboards/analytics",
        component: <Analytics />,
      },
    ],
  },
  // { type: "title", title: t("management"), key: "crud-pages" },
  // {
  //   type: "collapse",
  //   name: t("userManagement"),
  //   key: "user-management",
  //   icon: <GroupAddOutlinedIcon size="sm" />,
  //   //,
  //   collapse: [
  //     {
  //       name: t("employeesManagement"),
  //       key: "add-employee",
  //       route: "/userManagement/employees",
  //       component: <EmployeesManagement />,
  //       type: "users",
  //     },
  //     {
  //       name: t("customersManagement"),
  //       key: "add-customer",
  //       route: "/userManagement/customers",
  //       component: <CustomersManagement />,
  //       type: "users",
  //     },
  //   ],
  // },
  {
    type: "collapse",
    name: t("articlesManagement"),
    key: "article-management",
    icon: <EditCalendarOutlinedIcon fontSize="medium" />,
    collapse: [
      {
        name: t("articles"),
        key: "articles",
        route: "/articleManagement/articles",
        component: <Articles />,
        type: "article",
      },
      {
        name: t("tagManagement"),
        key: "tagManagement",
        route: "/articleManagement/tags",
        component: <Tags />,
        type: "tags",
      },
      {
        name: t("authors"),
        key: "authors",
        route: "/articleManagement/authors",
        component: <ArticleItems />,
        type: "authors",
      },
    ],
  },
  {
    type: "collapse",
    name: t("websiteManagement"),
    key: "website-management",
    icon: <EditCalendarOutlinedIcon fontSize="medium" />,
    collapse: [
      {
        name: t("sliders"),
        key: "sliders",
        route: "/websiteManagement/sliders",
        component: <Sliders />,
        type: "settings",
      },
      {
        name: t("websiteContent"),
        key: "websiteSettings",
        route: "/websiteManagement/websiteContent",
        component: <Settings />,
        type: "settings",
      },
    ],
  },
  {
    type: "collapse",
    name: t("projectManagement"),
    key: "project-management",
    icon: <EditCalendarOutlinedIcon fontSize="medium" />,
    collapse: [
      {
        name: t("projects"),
        key: "projects",
        route: "/projectManagement/projects",
        component: <Projects />,
        type: "projects",
      },
      {
        name: t("specialProjects"),
        key: "special-projects",
        route: "/projectManagement/special-projects",
        component: <SpecialProjects />,
        type: "projects",
      },
      {
        name: t("categories"),
        key: "categories",
        route: "/projectManagement/categories",
        component: <Categories />,
        type: "categories",
      },
      {
        name: t("timeline"),
        key: "timeline",
        route: "/projectManagement/timeline",
        component: <TimeLine />,
        type: "timeline",
      },
      {
        name: t("countries"),
        key: "countries",
        route: "/projectManagement/countries",
        component: <Items />,
        type: "countries",
      },
      {
        name: t("project_status"),
        key: "project_status",
        route: "/projectManagement/project_status",
        component: <Items />,
        type: "project_status",
      },
      {
        name: t("targetSegments"),
        key: "targetSegments",
        route: "/projectManagement/target_segment",
        component: <Items />,
        type: "target_segment",
      },
    ],
  },
];

export default routes;
