/**
=========================================================
* Arakan - v2.1.0
=========================================================

* Product Page: https://www.trakib.com/product/material-dashboard-pro-react
* Copyright 2022 Trakib (https://www.trakib.com)

Coded by www.trakib.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// @material-ui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Arakan components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDAlert from "components/MDAlert";

// Settings page components
import FormField from "layouts/pages/account/components/FormField";

import crudsService from "services/cruds-service";
import { Autocomplete, Switch } from "@mui/material";
import MDInput from "components/MDInput";
import i18n from "i18n";

function MainInfo({
  project,
  id,
  categories,
  allStatus,
  countries,
  targetSegments,
}) {
  const [category, setCategory] = useState([]);
  const [status, setStatus] = useState([]);
  const [country, setCountry] = useState([]);
  const [periodType, setPeriodType] = useState(null);
  const [targetSegment, setTargetSegment] = useState([]);
  const { t } = i18n;

  const [info, setInfo] = useState({
    cost: "",
    benficial_num: "",
    period: "",
    period_type:null,
    sequence: 0,
    urgent: "",
    category_id: null,
    impl_country_id: null,
    status_id: null,
    zakaat_available: false,
    show_in_home: false,
    all_amount_required: false,
    target_segments: [],
  });
  const [notification, setNotification] = useState({
    value: false,
    color: "info",
    message: "",
  });

  const periodTypes=[{id:"day",name:"يوم"},{id:"month",name:"شهر"},{id:"year",name:"سنة"}]

  useEffect(() => {
    setInfo({
      cost: project.cost,
      benficial_num: project.benficial_num,
      period: project.period,
      sequence: project.sequence,
      urgent: project.urgent,
      zakaat_available: project.zakaat_available,
      show_in_home: project.show_in_home,
      all_amount_required: project.all_amount_required,
    });
    const period_type = periodTypes.find(
      (period_type) => period_type.id === project.period_type
    );
    setPeriodType(period_type);
  }, [project]);

  useEffect(() => {
    if (project.impl_country_id !== "" && countries.length > 0) {
      const impl_country_id = countries.find((impl_country_id) => {
        return impl_country_id.id === project.impl_country_id.id;
      });
      setCountry(impl_country_id);
    }
  }, [countries, project]);

  useEffect(() => {
    if (project.category !== "" && categories.length > 0) {
      const category_id = categories.find(
        (category_id) => category_id.id === project.category.id
      );
      setCategory(category_id);
    }
  }, [categories, project]);



  useEffect(() => {
    if (project.status !== "" && allStatus.length > 0) {
      const status_id = allStatus.find(
        (status_id) => status_id.id === project.status.id
      );
      setStatus(status_id);
    }
  }, [allStatus, project]);

  const prepareNewArr = (arr, objKey) => {
    let newArr = [];
    for (const key in arr) {
      if (Object.hasOwnProperty.call(arr, key)) {
        const element = arr[key];
        newArr.push({
          id: element.target_segment_id.id,
          name: element.target_segment_id.translations[0][objKey || "name"],
        });
      }
    }
    return newArr;
  };

  useEffect(() => {
    if (project.target_segments.length > 0 && targetSegments.length > 0) {
      const tSegments = [];
      prepareNewArr(project.target_segments).forEach((targetSegmentItem) => {
        tSegments.push(targetSegmentItem);
      });
      setTargetSegment(tSegments);
    }
  }, [targetSegments, project]);

  useEffect(() => {
    if (notification.value === true) {
      setTimeout(() => {
        setNotification({ value: false, color: "info", message: "" });
      }, 5000);
    }
  }, [notification]);

  const [errors, setErrors] = useState({
    cost: false,
    benficial_num: false,
    period: false,
    sequence: false,
    urgent: false,
    category_id: false,
    impl_country_id: false,
    status_id: false,
    zakaat_available: false,
    show_in_home: false,
    all_amount_required: false,
    target_segments: false,
  });

  const changeHandler = (e) => {
    setInfo({
      ...info,
      [e.target.name]: e.target.value,
    });
  };

  const autoSwitchChangeHandler = (name, v) => {
    setInfo({
      ...info,
      [name]: v,
    });
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    if (!category) {
      setNotification({
        value: true,
        message: "لم تختار التصنيف",
        color: "error",
      });
      return;
    }
    if (!info.cost) {
      setNotification({
        value: true,
        message: "لم تدخل التكلفة",
        color: "error",
      });
      return;
    }
    if (!info.benficial_num) {
      setNotification({
        value: true,
        message: "لم تدخل عدد المستفيدين",
        color: "error",
      });
      return;
    }
    if (!country) {
      setNotification({
        value: true,
        message: "لم تختار بلد التنفيذ",
        color: "error",
      });
      return;
    }
    if (!info.period || !periodType) {
      setNotification({
        value: true,
        message: "لم تدخل المدة",
        color: "error",
      });
      return;
    }
    if (!status) {
      setNotification({
        value: true,
        message: "لم تختار الحالة",
        color: "error",
      });
      return;
    }
    const projectData = {
      cost: info.cost,
      benficial_num: info.benficial_num,
      period: info.period,
      period_type: periodType.id,
      sequence: info.sequence,
      urgent: info.urgent,
      category_id: category.id,
      impl_country_id: country.id,
      status_id: status.id,
      zakaat_available: info.zakaat_available,
      show_in_home: info.show_in_home,
      all_amount_required: info.all_amount_required,
    };
    // call api for update
    await crudsService.updateProjectInfo(projectData, project.id);

    // reset errors
    setErrors({
      firstNameError: false,
      lastNameError: false,
      emailError: false,
    });

    setNotification({
      value: true,
      color: "info",
      message: "Your profile has been updated",
    });
  };

  return (
    <>
      <Card id="basic-info" sx={{ overflow: "visible" }}>
        <MDBox p={3}>
          <MDTypography variant="h5">بيانات المشروع</MDTypography>
        </MDBox>
        <MDBox
          component="form"
          pb={3}
          px={3}
          method="POST"
          onSubmit={submitHandler}
        >
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <FormField
                onChange={changeHandler}
                label={t("cost")}
                name="cost"
                value={info.cost}
                placeholder="cost"
                error={errors.cost}
              />
            </Grid>
            <Grid item xs={3}>
              <FormField
                onChange={changeHandler}
                label="عدد المستفيدين"
                name="benficial_num"
                value={info.benficial_num}
                placeholder="benficial_num"
                error={errors.benficial_num}
              />
            </Grid>
            <Grid item xs={3}>
              <FormField
                onChange={changeHandler}
                label="المدة"
                name="period"
                value={info.period}
                placeholder={"period"}
                error={errors.benficial_num}
              />
            </Grid>
            <Grid item xs={3}>
              <Autocomplete
                options={periodTypes}
                name="period_type"
                renderOption={(props, option) => (
                  <li {...props} key={option.id}>
                    {option.data ? option.data.name : option.name}
                  </li>
                )}
                getOptionLabel={(option) => {
                  if (option.data) {
                    if (option.data.name) return option.data.name;
                  } else {
                    if (option) {
                      if (option.name) return option.name;
                    }
                  }
                  return "";
                }}
                isOptionEqualToValue={(option, item) =>
                  option === item || option?.id === item?.id
                }
                renderInput={(params) => (
                  <MDInput
                    variant="outlined"
                    {...params}
                    //variant="standard"
                    label="المدة"
                    error={errors.period_type}
                  />
                )}
                onChange={(e, v) => setPeriodType(v)}
                value={periodType}
              />
            </Grid>
            <Grid item xs={3}>
              <FormField
                onChange={changeHandler}
                label="الترتيب"
                name="sequence"
                value={info.sequence}
                placeholder={"sequence"}
                error={errors.sequence}
              />
            </Grid>
            <Grid item xs={3}>
              <Autocomplete
                variant="outlined"
                options={countries}
                name="impl_country_id"
                renderOption={(props, option) => (
                  <li {...props} key={option.id}>
                    {option.data ? option.data.name : option.name}
                  </li>
                )}
                getOptionLabel={(option) => {
                  if (option.data) {
                    if (option.data.name) return option.data.name;
                  } else {
                    if (option) {
                      if (option.name) return option.name;
                    }
                  }
                  return "";
                }}
                isOptionEqualToValue={(option, item) =>
                  option === item || option?.id === item?.id
                }
                renderInput={(params) => (
                  <MDInput
                    variant="outlined"
                    {...params}
                    //variant="standard"
                    label="بلد التنفيذ"
                    error={errors.impl_country_id}
                  />
                )}
                onChange={(e, v) => setCountry(v)}
                value={country}
              />
            </Grid>
            <Grid item xs={3}>
              <Autocomplete
                options={categories}
                name="category_id"
                renderOption={(props, option) => (
                  <li {...props} key={option.id}>
                    {option.data ? option.data.name : option.name}
                  </li>
                )}
                getOptionLabel={(option) => {
                  if (option.data) {
                    if (option.data.name) return option.data.name;
                  } else {
                    if (option) {
                      if (option.name) return option.name;
                    }
                  }
                  return "";
                }}
                isOptionEqualToValue={(option, item) =>
                  option === item || option?.id === item?.id
                }
                variant="outlined"
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    //variant="standard"
                    label="التصنيف"
                    error={errors.category_id}
                  />
                )}
                onChange={(e, v) => setCategory(v)}
                value={category}
              />
            </Grid>
            <Grid item xs={3}>
              <Autocomplete
                options={allStatus}
                name="status_id"
                renderOption={(props, option) => (
                  <li {...props} key={option.id}>
                    {option.data ? option.data.name : option.name}
                  </li>
                )}
                getOptionLabel={(option) => {
                  if (option.data) {
                    if (option.data.name) return option.data.name;
                  } else {
                    if (option) {
                      if (option.name) return option.name;
                    }
                  }
                  return "";
                }}
                isOptionEqualToValue={(option, item) =>
                  option === item || option?.id === item?.id
                }
                renderInput={(params) => (
                  <MDInput
                    variant="outlined"
                    {...params}
                    //variant="standard"
                    label="الحالة"
                    error={errors.status_id}
                  />
                )}
                onChange={(e, v) => setStatus(v)}
                value={status}
              />
            </Grid>
            <Grid item xs={3}>
              <Autocomplete
                multiple
                options={targetSegments}
                name="target_segments"
                onChange={async (event, newValue, reason, detail) => {
                  if (reason === "removeOption") {
                    console.log(project.target_segments, targetSegment);
                    let removedItem = project.target_segments.find(
                      (orgItem) =>
                        orgItem.target_segment_id.id === detail.option.id
                    );
                    await crudsService.removeProjectTargetSegment(
                      removedItem.id
                    );
                  } else if (reason === "selectOption")
                    await crudsService.addProjectTargetSegment({
                      target_segment_id: detail.option.id,
                      projects_id: project.id,
                    });
                  setTargetSegment(newValue);
                }}
                renderOption={(props, option) => (
                  <li {...props} key={option.id}>
                    {option.data ? option.data.name : option.name}
                  </li>
                )}
                getOptionLabel={(option) => {
                  if (option.data) {
                    if (option.data.name) return option.data.name;
                  } else {
                    if (option) {
                      if (option.name) return option.name;
                    }
                  }
                  return "";
                }}
                isOptionEqualToValue={(option, item) =>
                  option === item || option.id === item.id
                }
                renderInput={(params) => (
                  <MDInput
                    variant="outlined"
                    {...params}
                    //variant="standard"
                    label="الفئة المستهدفة"
                    error={errors.target_segments}
                  />
                )}
                // onChange={(e, v) => setTargetSegment(v)}
                value={targetSegment}
              />
            </Grid>
            <Grid item xs={3}>
              <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
                <MDBox mt={0.5}>
                  <Switch
                    // onChange={changeHandler}
                    name="zakaat_available"
                    checked={info.zakaat_available}
                    onChange={() =>
                      autoSwitchChangeHandler(
                        "zakaat_available",
                        !info.zakaat_available
                      )
                    }
                  />
                </MDBox>
                <MDBox width="80%" ml={0.5}>
                  <MDTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                  >
                    تجوز الزكاة
                  </MDTypography>
                </MDBox>
              </MDBox>
            </Grid>
            <Grid item xs={3}>
              <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
                <MDBox mt={0.5}>
                  <Switch
                    name="show_in_home"
                    checked={info.show_in_home}
                    // onChange={changeHandler}
                    onChange={() =>
                      autoSwitchChangeHandler(
                        "show_in_home",
                        !info.show_in_home
                      )
                    }
                  />
                </MDBox>
                <MDBox width="80%" ml={0.5}>
                  <MDTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                  >
                    العرض في الرئيسية
                  </MDTypography>
                </MDBox>
              </MDBox>
            </Grid>
            <Grid item xs={3}>
              <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
                <MDBox mt={0.5}>
                  <Switch
                    name="urgent"
                    checked={info.urgent}
                    // onChange={changeHandler}
                    onChange={() =>
                      autoSwitchChangeHandler("urgent", !info.urgent)
                    }
                  />
                </MDBox>

                <MDBox width="80%" ml={0.5}>
                  <MDTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                  >
                    عاجل
                  </MDTypography>
                </MDBox>
              </MDBox>
            </Grid>
            <Grid item xs={6}>
              <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
                <MDBox mt={0.5}>
                  <Switch
                    name="all_amount_required"
                    checked={info.all_amount_required}
                    // onChange={changeHandler}
                    onChange={() =>
                      autoSwitchChangeHandler(
                        "all_amount_required",
                        !info.all_amount_required
                      )
                    }
                  />
                </MDBox>
                <MDBox width="80%" ml={0.5}>
                  <MDTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                  >
                    قيمة المشروع بالكامل مطلوبة(مشاريع خاصة)
                  </MDTypography>
                </MDBox>
              </MDBox>
            </Grid>
          </Grid>
          <MDBox ml="auto" mt={2} display="flex" justifyContent="flex-end">
            <MDButton
              variant="gradient"
              color="dark"
              size="small"
              type="submit"
            >
              {t("update")}
            </MDButton>
          </MDBox>
        </MDBox>
      </Card>
      {notification.value === true && (
        <MDAlert color={notification.color} mt="20px">
          <MDTypography variant="body2" color="white">
            {notification.message}
          </MDTypography>
        </MDAlert>
      )}
    </>
  );
}

export default MainInfo;
