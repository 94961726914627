/**
=========================================================
* Arakan - v2.1.0
=========================================================

* Product Page: https://www.trakib.com/product/material-dashboard-pro-react
* Copyright 2022 Trakib (https://www.trakib.com)

Coded by www.trakib.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// @material-ui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Arakan components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDAlert from "components/MDAlert";

// Settings page components
import FormField from "layouts/pages/account/components/FormField";

import crudsService from "services/cruds-service";
import dataTableData from "layouts/applications/data-tables/data/dataTableData";
import StatusOnIcon from "@mui/icons-material/Wifi";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { Tooltip, IconButton } from "@mui/material";
import { InputLabel } from "@mui/material";
import MDInput from "components/MDInput";

function LangForm({ project, id }) {
  const [reportFile, setReportFile] = useState(null);
  const [fileState, setFileState] = useState(null);

  const [info, setInfo] = useState({
    name: "",
    summary: "",
    desc: "",
    promo_video_url: "",
    targets: "",
    result_video_url: "",
    report_desc: "",
    report_file: null,
  });
  const [notification, setNotification] = useState({
    value: false,
    color: "info",
    message: "",
  });

  useEffect(() => {
    setInfo({
      name: project.name,
      summary: project.summary,
      desc: project.desc,
      promo_video_url: project.promo_video_url,
      result_video_url: project.result_video_url,
      report_desc: project.report_desc,
      report_file: project.report_file,
      targets: project.targets,
    });
    //
    //
    let dd = project.targets.split("ــ");
    let Jh = [];

    dd.map((d) => {
      console.log(d);
      Jh.push({ text: d });
    });
    setInputs(Jh);

    setReportFile(project.report_file);
  }, [project]);

  useEffect(() => {
    if (notification.value === true) {
      setTimeout(() => {
        setNotification({ value: false, color: "info", message: "" });
      }, 5000);
    }
  }, [notification]);

  const [errors, setErrors] = useState({
    name: false,
    summary: false,
    desc: false,
    promo_video_url: false,
    result_video_url: false,
    report_desc: false,
    report_file: false,
    targets: false,
  });

  const changeHandler = (e) => {
    setInfo({
      ...info,
      [e.target.name]: e.target.value,
    });
  };

  const changeReportFileHandler = (e) => {
    setFileState(e.target.files[0]);
    setReportFile(URL.createObjectURL(e.target.files[0]));
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    if (info.name.trim().length === 0) {
      setNotification({
        value: true,
        message: "لم تدخل الاسم",
        color: "error",
      });
      return;
    }
    let fileId;
    if (fileState) fileId = await crudsService.fileUpload(fileState);
    let text = "";
    inputs.map((t, n) => {
      if (n === 0) text = t.text;
      else text = `${text}ــ${t.text}`;
    });

    const projectData = {
      name: info.name,
      summary: info.summary,
      desc: info.desc,
      promo_video_url: info.promo_video_url,
      result_video_url: info.result_video_url,
      report_desc: info.report_desc,
      report_file: fileId?.id,
      targets: text,
    };

    // console.log(inputs, text);
    // call api for update
    await crudsService.updateProject(projectData, id);

    // reset errors
    setErrors({
      firstNameError: false,
      lastNameError: false,
      emailError: false,
    });

    setNotification({
      value: true,
      color: "info",
      message: "تم التعديل بنجاح",
    });
  };

  ////

  const [inputs, setInputs] = useState([{ text: "" }]);

  // useEffect(() => {
  //   if (values[formField[`${field}_targets`].name] !== "")
  //     setInputs(values[formField[`${field}_targets`].name]);
  //   return () => {
  //     setInputs([{ text: "" }]);
  //   };
  // }, [values[formField[`${field}_targets`].name]]);

  const handleAddInput = () => {
    setInputs([...inputs, { text: "" }]);
  };

  const handleChange = (event, index) => {
    let { name, value } = event.target;
    let onChangeValue = [...inputs];
    onChangeValue[index][name] = value;
    setInputs(onChangeValue);
  };

  const handleDeleteInput = (index) => {
    const newArray = [...inputs];
    newArray.splice(index, 1);
    setInputs(newArray);
  };

  ////

  return (
    <>
      <Card sx={{ overflow: "visible" }}>
        <MDBox p={3}>
          <MDTypography variant="h5">
            {project.languages_code?.name}
          </MDTypography>
        </MDBox>
        <MDBox
          component="form"
          pb={3}
          px={3}
          method="POST"
          onSubmit={submitHandler}
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormField
                onChange={changeHandler}
                label="الاسم"
                name="name"
                value={info[`name`]}
                error={errors.nameError}
              />
            </Grid>
            <Grid item xs={12}>
              <FormField
                onChange={changeHandler}
                label="الملخص"
                name="summary"
                value={info[`summary`]}
                error={errors.nameError}
              />
            </Grid>
            <Grid item xs={12}>
              <FormField
                multiline
                rows={5}
                onChange={changeHandler}
                label="التفاصيل"
                name="desc"
                value={info[`desc`]}
                error={errors.nameError}
              />
            </Grid>
            <Grid item xs={12}>
              <FormField
                onChange={changeHandler}
                label="الفيديو الترويجي"
                name="promo_video_url"
                value={info[`promo_video_url`]}
                error={errors.nameError}
              />
            </Grid>
            {/* <Grid item xs={12}>
              <FormField
                multiline
                rows={5}
                onChange={changeHandler}
                label="الأهداف"
                name="targets"
                value={info[`targets`]}
                error={errors.nameError}
              />
            </Grid> */}
            <Grid item xs={12}>
              <FormField
                onChange={changeHandler}
                label="رابط فيديو النتيجة"
                name="result_video_url"
                value={info[`result_video_url`]}
                error={errors.nameError}
              />
            </Grid>
            <Grid item xs={12}>
              <FormField
                multiline
                rows={5}
                onChange={changeHandler}
                label="تفاصيل نتيجة المشروع"
                name="report_desc"
                value={info[`report_desc`]}
                error={errors.nameError}
              />
            </Grid>
            <Grid item xs={12}>
              <MDBox
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <MDBox mt={2} display="flex" flexDirection="column">
                  {reportFile && (
                    <MDBox ml={4} mt={2}>
                      <IconButton>
                        <AttachFileIcon />
                      </IconButton>
                    </MDBox>
                  )}
                  <InputLabel id="demo-simple-select-label">
                    ملف النتيجة
                  </InputLabel>
                  <MDInput
                    type="file"
                    name="attachment"
                    onChange={changeReportFileHandler}
                    id="file-input"
                    accept="image/*"
                    sx={{ mt: "16px" }}
                    label="ملف النتيجة"
                    name="report_file"
                    error={errors.nameError}
                  ></MDInput>
                </MDBox>
              </MDBox>
            </Grid>
            <Grid item xs={12}>
              <MDTypography variant="h6">الأهداف</MDTypography>
              {inputs.map((item, index) => (
                <Grid container spacing={3}>
                  <Grid item xs={10}>
                    <FormField
                      name="text"
                      type="text"
                      value={item.text}
                      onChange={(event) => handleChange(event, index)}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    {inputs.length > 1 && (
                      <MDButton
                        onClick={() => handleDeleteInput(index)}
                        size="large"
                      >
                        -
                      </MDButton>
                    )}
                  </Grid>
                  <Grid item xs={1}>
                    {index === inputs.length - 1 && (
                      <MDButton onClick={() => handleAddInput()} size="large">
                        +
                      </MDButton>
                    )}
                  </Grid>
                </Grid>
              ))}

              {/* <div className="body"> {JSON.stringify(inputs)} </div> */}
            </Grid>
          </Grid>
          <MDBox ml="auto" mt={2} display="flex" justifyContent="flex-end">
            <MDButton
              variant="gradient"
              color="dark"
              size="small"
              type="submit"
            >
              تعديل
            </MDButton>
          </MDBox>
        </MDBox>
      </Card>
      {notification.value === true && (
        <MDAlert color={notification.color} mt="20px">
          <MDTypography variant="body2" color="white">
            {notification.message}
          </MDTypography>
        </MDAlert>
      )}
    </>
  );
}

export default LangForm;
