/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// formik components
import { Formik, Form } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// AddSlider page components
import LangForm from "./components/LangForm";

// AddSlider layout schemas for form and form feilds
import validations from "./schemas/validations";
import initialValues from "./schemas/initialValues";
import form from "./schemas/form";

import crudsService from "services/cruds-service";
import Other from "./components/Other";
import GalleryImage from "./components/GalleryImage";

function AddSlider() {
  const [activeStep, setActiveStep] = useState(0);
  const [steps, setSteps] = useState([]);
  const [pages, setPages] = useState([]);
  const [isLastStep, setIsLastStep] = useState(false);
  const { formId, formField } = form;

  const currentValidation = validations[activeStep];

  // const prepareNewArr = (arr, objKey) => {
  //   let newArr = [];
  //   for (const key in arr) {
  //     if (Object.hasOwnProperty.call(arr, key)) {
  //       const element = arr[key];
  //       newArr.push({
  //         id: element.id,
  //         name: element.name,
  //         code: element.code,
  //       });
  //     }
  //   }
  //   return newArr;
  // };

  useEffect(() => {
    (async () => {
      try {
        const { data } = await crudsService.getLanguages();
        setSteps(data);
        const pagesData = await crudsService.getPages();
        setPages(pagesData.data);
      } catch (err) {
        console.error(err);
        return null;
      }
    })();
  }, []);

  const getStepContent = (stepIndex, formData) => {
    if (stepIndex === 0)
      return (
        <GalleryImage formData={formData} lang={{ name: "الصورة الرئيسية" }} />
      );
    else if (stepIndex === steps.length + 1)
      return (
        <Other
          formData={formData}
          lang={{ name: "البيانات الأساسية" }}
          pages={pages}
        />
      );
    else return <LangForm formData={formData} lang={steps[stepIndex - 1]} />;
  };

  useEffect(() => {
    setIsLastStep(steps.length > 0 && activeStep === steps.length + 1);
  }, [activeStep]);

  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });

  const handleBack = () => setActiveStep(activeStep - 1);

  const submitForm = async (values, actions) => {
    await sleep(1000);
    // eslint-disable-next-line no-alert
    values.type = 1;
    console.log(values);
    // alert(JSON.stringify(values, null, 2));

    crudsService.pushReq(values, "settings");
    actions.setSubmitting(false);
    actions.resetForm();
    setActiveStep(0);
  };

  const handleSubmit = (values, actions) => {
    if (isLastStep) {
      submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  if (steps.length === 0) return null;

  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      <MDBox py={3} mb={20} height="65vh">
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100%", mt: 8 }}
        >
          <Grid item xs={12} lg={8}>
            <Formik
              initialValues={initialValues}
              validationSchema={currentValidation}
              onSubmit={handleSubmit}
              enableReinitialize
            >
              {({
                values,
                errors,
                touched,
                isSubmitting,
                setFieldValue,
                setFieldTouched,
              }) => (
                <Form id={formId} autoComplete="off">
                  <Card sx={{ height: "100%" }}>
                    <MDBox mx={2} mt={-3}>
                      <Stepper activeStep={activeStep} alternativeLabel>
                        {[
                          { name: "الصورة الرئيسية" },
                          ...steps,
                          { name: "البيانات الأساسية" },
                        ].map((label) => (
                          <Step key={label.name}>
                            <StepLabel>{label.name}</StepLabel>
                          </Step>
                        ))}
                      </Stepper>
                    </MDBox>
                    <MDBox p={3}>
                      <MDBox>
                        {getStepContent(activeStep, {
                          values,
                          touched,
                          formField,
                          errors,
                          setFieldValue,
                          setFieldTouched,
                        })}
                        <MDBox
                          mt={2}
                          width="100%"
                          display="flex"
                          justifyContent="space-between"
                        >
                          {activeStep === 0 ? (
                            <MDBox />
                          ) : (
                            <MDButton
                              variant="gradient"
                              color="light"
                              onClick={handleBack}
                            >
                              العودة
                            </MDButton>
                          )}
                          <MDButton
                            disabled={isSubmitting}
                            type="submit"
                            variant="gradient"
                            color="dark"
                          >
                            {isLastStep ? "إرسال" : "التالي"}
                          </MDButton>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </Card>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AddSlider;
