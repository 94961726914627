/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com
 
 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// NewUser page components
import { useEffect, useMemo, useState } from "react";
import FormField from "layouts/pages/account/components/FormField";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
// import { setDate } from "date-fns";
import { Autocomplete, Card } from "@mui/material";
import crudsService from "services/cruds-service";

import Grid from "@mui/material/Grid";
import MDAlert from "components/MDAlert";
function TimeLineForm({ project, timeLineItems, getProjects }) {
  // const { formField, values, errors, setFieldValue, setFieldTouched } =
  //   formData;
  // const { time_line_items } = formField;
  // const { time_line_items: time_line_itemsV } = values;
  const [notification, setNotification] = useState({
    value: false,
    color: "info",
    message: "",
  });

  useEffect(() => {
    if (notification.value === true) {
      setTimeout(() => {
        setNotification({ value: false, color: "info", message: "" });
      }, 5000);
    }
  }, [notification]);

  const [timeLineItem, setTimeLineItem] = useState(null);
  const [date, setDate] = useState(null);

  const handleAddTimeLine = async () => {
    if (!timeLineItem) {
      setNotification({
        value: true,
        message: "لم تختار أي عنصر",
        color: "error",
      });
      return;
    }
    let obj = {
      projects_id: project.id,
      timeline_id: timeLineItem.id,
      date: date,
    };
    await crudsService.addTimelineItem(obj);
    getProjects();
    setNotification({
      value: true,
      color: "info",
      message: "تم التعديل بنجاح",
    });
  };

  const deleteItem = async (b) => {
    await crudsService.deleteTimelineItem(b);
    getProjects();
    setNotification({
      value: true,
      color: "info",
      message: "تم الحذف بنجاح",
    });
  };

  const getItems = () => {
    return project.time_line_items.map((t, i) => (
      <MDBox display="flex" justifyContent="center">
        <Card
          sx={{ m: 1, px: 2, py: 1, width: "50%" }}
          onClick={() => {
            deleteItem(t.id);
          }}
        >
          <MDBox>
            <MDTypography key={Math.random()} variant="body2">
              {t.timeline_id.translations[0].title}
            </MDTypography>
            <MDBox display="flex" justifyContent="flex-end">
              <MDTypography key={Math.random()} variant="overline">
                {t.date}
              </MDTypography>
            </MDBox>
          </MDBox>
        </Card>{" "}
      </MDBox>
    ));
  };

  return (
    <>
      <Card id="time-line" sx={{ overflow: "visible" }}>
        <MDBox p={3}>
          <MDTypography variant="h5">الخط الزمني </MDTypography>
          <MDTypography variant="button">
            يتم مسح العنصر بالضغط عليها
          </MDTypography>
        </MDBox>
        <MDBox component="form" pb={3} px={3}>
          <MDBox mt={1.625}>{getItems()}</MDBox>
          <MDBox mt={1.625} display="flex">
            <Grid container spacing={3}>
              <Grid item xs={2}></Grid>
              <Grid item xs={4}>
                <Autocomplete
                  label="الخط الزمني"
                  options={timeLineItems}
                  name="timeLineItem"
                  renderOption={(props, option) => (
                    <li {...props} key={option.id}>
                      {option.data ? option.data.name : option.name}
                    </li>
                  )}
                  getOptionLabel={(option) => {
                    if (option.data) {
                      if (option.data.name) return option.data.name;
                    } else {
                      if (option) {
                        if (option.name) return option.name;
                      }
                    }
                    return "";
                  }}
                  isOptionEqualToValue={(option, item) =>
                    option === item || option.id === item.id
                  }
                  renderInput={(params) => (
                    <MDInput
                      {...params}
                      variant="standard"
                      label="timeLineItem"
                      label="الخط الزمني"
                    />
                  )}
                  onChange={(e, v) => setTimeLineItem(v)}
                  value={timeLineItem}
                />
              </Grid>
              <Grid item xs={4}>
                <FormField
                  type="date"
                  label="التاريخ"
                  name="date"
                  value={date}
                  onChange={(e, v) => setDate(e.target.value)}
                />
              </Grid>
              <Grid item xs={2}></Grid>
            </Grid>
          </MDBox>
          <MDBox ml="auto" mt={2} display="flex" justifyContent="flex-end">
            <MDButton
              variant="gradient"
              color="dark"
              size="small"
              onClick={handleAddTimeLine}
            >
              إضافة
            </MDButton>
          </MDBox>
        </MDBox>
      </Card>
      {notification.value === true && (
        <MDAlert color={notification.color} mt="20px">
          <MDTypography variant="body2" color="white">
            {notification.message}
          </MDTypography>
        </MDAlert>
      )}
    </>
  );
}

// typechecking props for TimeLineForm
TimeLineForm.propTypes = {
  // formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default TimeLineForm;
