/**
=========================================================
* Arakan - v2.1.0
=========================================================

* Product Page: https://www.trakib.com/product/material-dashboard-pro-react
* Copyright 2022 Trakib (https://www.trakib.com)

Coded by www.trakib.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Arakan components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAlert from "components/MDAlert";

// Arakan examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
// import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
// import Icon from "@mui/material/Icon";
// import Grid from "@mui/material/Grid";
// import booking3 from "assets/images/products/product-3-min.jpg";

// import AuthService from "services/auth-service";
import CrudService from "services/cruds-service";
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { AbilityContext } from "Can";
import { useAbility } from "@casl/react";
import FilterProject from "./filter-project";
import StatusOnIcon from "@mui/icons-material/Wifi";
import StatusOffIcon from "@mui/icons-material/WifiOff";
import { Tooltip, IconButton } from "@mui/material";

// import BookingCard from "examples/Cards/BookingCard";
import i18n from "i18n";
import MDAvatar from "components/MDAvatar";
import MDButton from "components/MDButton";
import ProductCellWithoutImage from "components/ProductCellWithoutImage";

function Items() {
  let { state } = useLocation();
  const { t } = i18n;

  const ability = useAbility(AbilityContext);
  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);
  // const [statusStatistics, setStatusStatistics] = useState([]);
  const [meta, setMeta] = useState(null);
  const [page, setPage] = useState(1);
  const [limitChange, setLimitChange] = useState(false);
  const [sortVal, setSortVal] = useState(null);
  const [sortUrl, setSortUrl] = useState("&sort=-date_created");
  // const [isAdmin, setIsAdmin] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  // const [appointmentsInfo, setAppointmentsInfo] = useState(false);
  const [filterUrl, setFilterUrl] = useState(null);
  const [defaultFilterUrl, setDefaultFilterUrl] = useState("");
  const [notification, setNotification] = useState({
    value: false,
    text: "",
  });

  const navigate = useNavigate();
  const baseUrl = process.env.REACT_APP_API_URL;

  const getProjects = async (filUrl) => {
    console.log("hi");
    const URL = `${filUrl ? `${filUrl}&page=${page}` : `&page=${page}`}`;
    console.log(URL);
    const response = await CrudService.getProjects(URL);
    setData(response.data);
    setMeta(response.meta);
  };

  useEffect(() => {
    console.log("page", page);
    getProjects(`${filterUrl || ""}${sortUrl}`);
  }, [page]);

  useEffect(() => {
    if (data.length > 0) {
      console.log("limitChange", limitChange);
      getProjects(`${filterUrl || ""}${sortUrl}`);
      setLimitChange(false);
    }
  }, [limitChange]);

  useEffect(() => {
    // if (page === 1) getProjects(`${filterUrl || ""}${sortUrl}`);
    // else if (page !== 1) setPage(1);

    if (data.length > 0) {
      console.log("filterUrl, sortUrl", filterUrl, sortUrl);
      setPage(1);
    }
  }, [filterUrl, sortUrl]);

  useEffect(() => {
    setTableData(getRows(data));
  }, [data]);

  useEffect(() => {
    if (!state) return;
    setNotification({
      value: state.value,
      text: state.text,
    });
  }, [state]);

  useEffect(() => {
    if (notification.value === true) {
      setTimeout(() => {
        setNotification({
          value: false,
          text: "",
        });
      }, 5000);
    }
  }, [notification]);

  const updateStatusHandler = async (e, data) => {
    let obj;
    if (data.status === "published") obj = { status: "draft" };
    else obj = { status: "published" };
    await CrudService.updateProjectInfo(obj, data.id);

    getProjects(`${filterUrl || ""}${sortUrl}`);
    setNotification({
      value: true,
      text: t("statusChanged"),
    });
  };

  const clickShowProjectDetailsHandler = (id, clinicId) => {
    // (ability.can("edit", "projects") || isAdmin) &&
    navigate(`/projectManagement/${id}`);
  };

  const getRows = (info) => {
    let updatedInfo = info.map((row) => {
      return {
        id: row.id,
        name: row.translations[0].name,
        summary: row.translations[0].summary,
        desc: row.translations[0].desc,
        promo_video_url: row.translations[0].promo_video_url,
        category: row.category_id.translations[0].name,
        cost: row.cost,
        benficial_num: row.benficial_num,
        impl_country_id: row.impl_country_id.translations[0].name,
        period: row.period + " " + row.period_type,
        pStatus: row.status_id.translations[0].name,
        zakaat_available: row.zakaat_available,
        show_in_home: row.show_in_home,
        all_amount_required: row.all_amount_required,
        urgent: row.urgent,
        time_line_items: row.time_line_items,
        target_segments: row.target_segments,
        targets: row.translations[0].targets,
        gallery: row.gallery,
        pic: row.translations[0].pic,
        status: row.status,
      };
    });
    console.log(info, updatedInfo);
    return updatedInfo;
  };

  const dataTableData = {
    columns: [
      // {
      //   Header: "الصورة الرئيسية",
      //   accessor: "pic",
      //   Cell: (info) => {
      //     return (
      //       <MDAvatar
      //         src={`${baseUrl}assets/${info.cell.row.values.pic}`}
      //         alt="Avatar"
      //         variant="square"
      //       />
      //     );
      //   },
      // },
      {
        Header: "البيانات",
        accessor: "info",
        Cell: (info) => {
          return (
            <MDBox
              onClick={() =>
                clickShowProjectDetailsHandler(info.cell.row.original.id)
              }
            >
              <ProductCellWithoutImage
                array={[
                  info.cell.row.original.category,
                  info.cell.row.original.name,
                  info.cell.row.original.pStatus,
                ]}
              />
            </MDBox>
          );
        },
      },
      // {
      //   Header: "التفاصيل",
      //   accessor: "desc",
      //   Cell: (info) => {
      //     return (
      //       <MDBox
      //         alignItems="center"
      //         width={200}
      //         sx={{ cursor: "pointer" }}
      //         onClick={() =>
      //           clickShowProjectDetailsHandler(info.cell.row.original.id)
      //         }
      //       >
      //         <MDTypography variant="caption" fontWeight="medium">
      //           {info.cell.row.original.desc?.length > 50
      //             ? info.cell.row.original.desc?.slice(0, 50 - 1) + "..."
      //             : info.cell.row.original.desc}
      //         </MDTypography>
      //       </MDBox>
      //     );
      //   },
      // },
      {
        Header: t("country"),
        accessor: "impl_country_id",
        width: "10%",
      },
      { Header: t("cost"), accessor: "cost", width: "10%" },
      { Header: "عدد المستفيدين", accessor: "benficial_num", width: "10%" },
      { Header: "مدة التنفيذ", accessor: "period", width: "10%" },
      {
        Header: t("actions"),
        disableSortBy: true,
        accessor: "actions",
        Cell: (info) => {
          return (
            <MDBox display="flex" alignItems="center">
              {info.cell.row.original.status === "draft" ? (
                <Tooltip
                  title={t("changeStatus")}
                  onClick={(e) =>
                    updateStatusHandler(e, info.cell.row.original)
                  }
                >
                  <IconButton>
                    <StatusOffIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip
                  title={t("changeStatus")}
                  onClick={(e) =>
                    updateStatusHandler(e, info.cell.row.original)
                  }
                >
                  <IconButton>
                    <StatusOnIcon />
                  </IconButton>
                </Tooltip>
              )}
              <Tooltip
                title={t("edit")}
                onClick={(e) =>
                  clickShowProjectDetailsHandler(info.cell.row.original.id)
                }
              >
                <IconButton>
                  <EditIcon />
                </IconButton>
              </Tooltip>
            </MDBox>
          );
        },
      },
    ],
    rows: tableData,
  };

  const clickAddHandler = () => {
    navigate("/projectManagement/projects/add/project");
  };

  const getCsvRows = (info) => {
    // let updatedInfo = info.map((row) => {
    //   let csv = {
    //     id: row.id,
    //     date_created: moment(row.date_created).format(format1),
    //     responsible: `${row.responsible_id.first_name} ${
    //       row.responsible_id.last_name ? row.responsible_id.last_name : ""
    //     }`,
    //     pationt: row.pationt_id.first_name,
    //     pationtPhone: row.pationt_id.phone,
    //     clinic: row.clinic_id.name,
    //     smsource: row.source_id.name,
    //     details: row.details,
    //     gclid: row.gclid,
    //     fbclid: row.fbclid,
    //     campaign: row.campaign,
    //     source: row.source,
    //     medium: row.medium,
    //     term: row.term,
    //     content: row.content,
    //     status: row.status_id.name,
    //   };
    //   const keys = Object.keys(csv);
    //   let csvObj = {};
    //   keys.forEach((element) => {
    //     csvObj[t(element)] = csv[element];
    //   });
    //   return csvObj;
    // });
    // return updatedInfo;
    return {};
  };

  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      {notification.value && (
        <MDAlert color="info" my="20px">
          <MDTypography variant="body2" color="white">
            {notification.text}
          </MDTypography>
        </MDAlert>
      )}
      <MDBox pb={3}>
        <MDBox mb={3}>
          <Card>
            <MDBox
              p={3}
              lineHeight={1}
              display="flex"
              justifyContent="space-between"
            >
              <MDTypography variant="h5" fontWeight="medium">
                {t("projectManagement")}
              </MDTypography>
              <MDBox display="flex">
                <MDButton
                  variant="gradient"
                  color="dark"
                  size="small"
                  type="submit"
                  onClick={clickAddHandler}
                  mx={1}
                >
                  + {t("addItem")}
                </MDButton>
                <MDButton
                  variant="gradient"
                  color="dark"
                  size="small"
                  type="submit"
                  onClick={() => setShowFilter(!showFilter)}
                  sx={{ mx: 1 }}
                >
                  {t("filter")}
                </MDButton>

                {/* <MDBox sx={{ cursor: "pointer" }}>
                    <CsvDownloadButton
                      sx={{ cursor: "pointer" }}
                      data={getCsvRows(data)}
                      style={{
                        backgroundColor: "rgb(37 37 39)",
                        color: "#fff", 
                        fontSize: "15px",
                        fontWeight: "bold",
                        padding: "6px 24px",
                        borderRadius: "6px",
                      }}
                      delimiter=","
                    >
                      {t("export")}
                    </CsvDownloadButton>
                  </MDBox> */}
              </MDBox>
            </MDBox>
            {showFilter && (
              <FilterProject
                setFilterUrl={setFilterUrl}
                filterUrl={filterUrl}
              />
            )}
            <MDBox mt={1.5} px={3}></MDBox>
            <DataTable
              setLimitChange={setLimitChange}
              table={dataTableData}
              meta={meta}
              setPage={setPage}
              setSortVal={setSortVal}
              pageNum={page}
              isSorted={false}
            />
          </Card>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Items;
