/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const form = {
  formId: "new-tag-form",
  formField: {
    ar: {
      name: "ar",
      label: "الاسم",
      type: "text",
      errorMsg: "Arabic name is required.",
    },
    en: {
      name: "en",
      label: "الاسم",
      type: "text",
      errorMsg: "english name is required.",
    },
    tr: {
      name: "tr",
      label: "الاسم",
      type: "text",
      errorMsg: "turkish name is required.",
    },
    parent_id: {
      name: "parent_id",
      label: "التصنيف الرئيسي",
      type: "parent_id",
    },
  },
};

export default form;
