/**
=========================================================
* Arakan - v2.1.0
=========================================================
* Product Page: https://www.trakib.com/product/material-dashboard-pro-react
* Copyright 2022 Trakib (https://www.trakib.com)
Coded by www.trakib.com
 =========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Arakan components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDAlert from "components/MDAlert";
import MDInput from "components/MDInput";
import CrudService from "services/cruds-service";

function Header({ project, data }) {
  const baseUrl = process.env.REACT_APP_API_URL;
  const [image, setImage] = useState("");
  const [fileState, setFileState] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [notification, setNotification] = useState({
    value: false,
    color: "info",
    message: "",
  });
  const [error, setError] = useState(null);

  const [id, setId] = useState(null);

  useEffect(() => {
    if (!project) {
      return;
    }
    let obj = {};

    obj[project.languages_code?.code] = `${baseUrl}assets/${data}`;
    data && setImage(obj);
    setId(project.id);
  }, [project]);

  useEffect(() => {
    if (notification.value === true) {
      setTimeout(() => {
        setNotification({ value: false, color: "info", message: "" });
      }, 5000);
    }
  }, [notification]);

  const changeHandler = (e) => {
    const formData = new FormData();
    formData.append("attachment", e.target.files[0]);
    setFileState(formData);
    let obj1 = {};
    let obj2 = {};
    obj1[project.languages_code?.code] = URL.createObjectURL(e.target.files[0]);
    setImageUrl(obj1);
    obj2[project.languages_code?.code] = e.target.files[0];
    setImage(obj2);
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    try {
      await CrudService.uploadAndUpdateProjectImage(fileState, id);
      setNotification({
        value: true,
        color: "info",
        message: "Your profile has been updated",
      });
    } catch (err) {
      setError(err.message);
      return null;
    }
    setError(null);
  };
  if (!project) return null;

  return (
    <>
      <Card id={`profile${project.languages_code?.code}`}>
        <MDBox
          p={2}
          component="form"
          onSubmit={submitHandler}
          encType="multipart/form-data"
        >
          {((imageUrl && imageUrl[project.languages_code?.code]) ||
            (image && image[project.languages_code?.code])) && (
            <MDBox
              component="img"
              src={
                (imageUrl && imageUrl[project.languages_code?.code]) ??
                (image && image[project.languages_code?.code])
              }
              alt="Product Image"
              borderRadius="lg"
              shadow="sm"
              width="100%"
              height="100%"
              mb={2}
            />
          )}
          <Grid
            container
            spacing={3}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            marginTop="0"
            marginLeft="0"
            width="100%"
          >
            <MDInput
              type="file"
              onChange={changeHandler}
              id={`avatar${project.languages_code?.code}`}
              name={`attachment${project.languages_code?.code}`}
              accept="image/*"
              sx={{ display: "none", cursor: "pointer" }}
            ></MDInput>
            <MDBox
              height="100%"
              mt={0.5}
              lineHeight={1}
              display="flex"
              flexDirection="column"
              justifyContent="center"
            >
              <MDTypography variant="h5" fontWeight="medium">
                {"الصورة الرئيسية"}
              </MDTypography>
              <MDTypography variant="button" color="text" fontWeight="medium">
                {project.languages_code?.name}
              </MDTypography>
            </MDBox>
            <MDBox sx={{ mt: 1 }} display="flex">
              <MDBox
                display="flex"
                justifyContent="center"
                flexDirection="row"
                width="100%"
              >
                <MDButton
                  variant="gradient"
                  color="info"
                  size="small"
                  component="label"
                  htmlFor={`avatar${project.languages_code?.code}`}
                  sx={{ marginRight: "1rem" }}
                >
                  تغيير الصورة
                </MDButton>
                <MDButton
                  variant="gradient"
                  color="dark"
                  size="small"
                  type="submit"
                >
                  حفظ
                </MDButton>
              </MDBox>
              {error && (
                <MDTypography
                  variant="caption"
                  color="error"
                  fontWeight="light"
                  pt={2}
                >
                  {error}
                </MDTypography>
              )}
            </MDBox>
          </Grid>
        </MDBox>
      </Card>
      {notification.value === true && (
        <MDAlert color={notification.color} mt="20px">
          <MDTypography variant="body2" color="white">
            {notification.message}
          </MDTypography>
        </MDAlert>
      )}
    </>
  );
}

export default Header;
