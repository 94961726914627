/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// NewUser page components
import { Autocomplete, Switch } from "@mui/material";
// import { useState } from "react";
import MDInput from "components/MDInput";
import FormField from "../FormField";

function Other({
  formData,
  lang,
  countries = [],
  categories = [],
  allStatus = [],
  targetSegments = [],
}) {
  const periodTypes=[{id:"day",name:"يوم"},{id:"month",name:"شهر"},{id:"year",name:"سنة"}]
  const { formField, values, errors, touched, setFieldValue, setFieldTouched } =
    formData;
  const {
    category_id,
    cost,
    benficial_num,
    impl_country_id,
    period,
    period_type,
    status_id,
    zakaat_available,
    show_in_home,
    all_amount_required,
    urgent,
    target_segments,
  } = formField;
  const {
    cost: costV,
    benficial_num: benficial_numV,
    period: periodV,
    period_type: period_typeV,
    impl_country_id: impl_country_idV,
    category_id: category_idV,
    status_id: status_idV,
    zakaat_available: zakaat_availableV,
    show_in_home: show_in_homeV,
    all_amount_required: all_amount_requiredV,
    urgent: urgentV,
    target_segments: target_segmentsV,
  } = values;

  const autoChangeHandler = (name, newValue) => {
    setFieldValue(name, newValue);
    setFieldTouched(name, newValue);
  };

  const autoSwitchChangeHandler = (name, newValue) => {
    setFieldValue(name, newValue);
    setFieldTouched(name, newValue);
  };

  return (
    <MDBox>
      <MDTypography variant="h5" fontWeight="bold">
        {lang?.name}
      </MDTypography>
      <MDBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <FormField
              type={cost.type}
              label={cost.label}
              name={cost.name}
              value={costV}
              placeholder={cost.placeholder}
              error={errors.cost && touched.cost}
              success={costV?.length > 0 && !errors.cost}
            />
          </Grid>
          <Grid item xs={3}>
            <FormField
              type={benficial_num.type}
              label={benficial_num.label}
              name={benficial_num.name}
              value={benficial_numV}
              placeholder={benficial_num.placeholder}
              error={errors.benficial_num && touched.benficial_num}
              success={benficial_numV?.length > 0 && !errors.benficial_num}
            />
          </Grid>
          <Grid item xs={3}>
            <FormField
              type={period.type}
              label={period.label}
              name={period.name}
              value={periodV}
              placeholder={period.placeholder}
              error={errors.period && touched.period}
              success={periodV?.length > 0 && !errors.period}
            />
          </Grid>
          <Grid item xs={3}>
            <Autocomplete
              options={periodTypes}
              name={period_type.name}
              renderOption={(props, option) => (
                <li {...props} key={option.id}>
                  {option.data ? option.data.name : option.name}
                </li>
              )}
              getOptionLabel={(option) => {
                if (option.data) {
                  if (option.data.name) return option.data.name;
                } else {
                  if (option) {
                    if (option.name) return option.name;
                  }
                }
                return "";
              }}
              isOptionEqualToValue={(option, item) =>
                option === item || option.id === item.id
              }
              variant="outlined"
              renderInput={(params) => (
                <MDInput
                  {...params}
                  //variant="standard"
                  label={period_type.label}
                  error={errors.period_type && touched.period_type}
                  success={period_typeV?.length > 0 && !errors.period_type}
                />
              )}
              onChange={(e, v) => autoChangeHandler(period_type.name, v)}
              value={period_typeV}
            />
          </Grid>
          <Grid item xs={3}>
            <Autocomplete
              variant="outlined"
              options={countries}
              name={impl_country_id.name}
              renderOption={(props, option) => (
                <li {...props} key={option.id}>
                  {option.data ? option.data.name : option.name}
                </li>
              )}
              getOptionLabel={(option) => {
                if (option.data) {
                  if (option.data.name) return option.data.name;
                } else {
                  if (option) {
                    if (option.name) return option.name;
                  }
                }
                return "";
              }}
              isOptionEqualToValue={(option, item) =>
                option === item || option.id === item.id
              }
              renderInput={(params) => (
                <MDInput
                  variant="outlined"
                  {...params}
                  //variant="standard"
                  label={impl_country_id.label}
                  error={errors.impl_country_id && touched.impl_country_id}
                  success={
                    impl_country_idV?.length > 0 && !errors.impl_country_id
                  }
                />
              )}
              // onChange={autoImplCountryIdChangeHandler}
              onChange={(e, v) => autoChangeHandler(impl_country_id.name, v)}
              value={impl_country_idV}
            />
          </Grid>
          <Grid item xs={3}>
            <Autocomplete
              options={categories}
              name={category_id.name}
              renderOption={(props, option) => (
                <li {...props} key={option.id}>
                  {option.data ? option.data.name : option.name}
                </li>
              )}
              getOptionLabel={(option) => {
                if (option.data) {
                  if (option.data.name) return option.data.name;
                } else {
                  if (option) {
                    if (option.name) return option.name;
                  }
                }
                return "";
              }}
              isOptionEqualToValue={(option, item) =>
                option === item || option.id === item.id
              }
              variant="outlined"
              renderInput={(params) => (
                <MDInput
                  {...params}
                  //variant="standard"
                  label={category_id.label}
                  error={errors.category_id && touched.category_id}
                  success={category_idV?.length > 0 && !errors.category_id}
                />
              )}
              onChange={(e, v) => autoChangeHandler(category_id.name, v)}
              value={category_idV}
            />
          </Grid>
          <Grid item xs={3}>
            <Autocomplete
              options={allStatus}
              name={status_id.name}
              renderOption={(props, option) => (
                <li {...props} key={option.id}>
                  {option.data ? option.data.name : option.name}
                </li>
              )}
              getOptionLabel={(option) => {
                if (option.data) {
                  if (option.data.name) return option.data.name;
                } else {
                  if (option) {
                    if (option.name) return option.name;
                  }
                }
                return "";
              }}
              isOptionEqualToValue={(option, item) =>
                option === item || option.id === item.id
              }
              renderInput={(params) => (
                <MDInput
                  variant="outlined"
                  {...params}
                  //variant="standard"
                  label={status_id.label}
                  error={errors.status_id && touched.status_id}
                  success={status_idV?.length > 0 && !errors.status_id}
                />
              )}
              // onChange={autoStatusIdChangeHandler}
              onChange={(e, v) => autoChangeHandler(status_id.name, v)}
              value={status_idV}
            />
          </Grid>
          <Grid item xs={3}>
            <Autocomplete
              multiple
              options={targetSegments}
              name={target_segments.name}
              renderOption={(props, option) => (
                <li {...props} key={option.id}>
                  {option.data ? option.data.name : option.name}
                </li>
              )}
              getOptionLabel={(option) => {
                if (option.data) {
                  if (option.data.name) return option.data.name;
                } else {
                  if (option) {
                    if (option.name) return option.name;
                  }
                }
                return "";
              }}
              isOptionEqualToValue={(option, item) =>
                option === item || option.id === item.id
              }
              renderInput={(params) => (
                <MDInput
                  variant="outlined"
                  {...params}
                  //variant="standard"
                  label={target_segments.label}
                  error={errors.target_segments && touched.target_segments}
                  success={
                    target_segmentsV?.length > 0 && !errors.target_segments
                  }
                />
              )}
              // onChange={autoImplCountryIdChangeHandler}
              onChange={(e, v) => autoChangeHandler(target_segments.name, v)}
              value={target_segmentsV}
            />
          </Grid>
          <Grid item xs={3}>
            <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
              <MDBox mt={0.5}>
                <Switch
                  name={zakaat_available.name}
                  checked={zakaat_availableV}
                  onChange={() =>
                    autoSwitchChangeHandler(
                      zakaat_available.name,
                      !zakaat_availableV
                    )
                  }
                />
              </MDBox>
              <MDBox width="80%" ml={0.5}>
                <MDTypography
                  variant="button"
                  fontWeight="regular"
                  color="text"
                >
                  {zakaat_available.label}
                </MDTypography>
              </MDBox>
            </MDBox>
          </Grid>
          <Grid item xs={3}>
            <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
              <MDBox mt={0.5}>
                <Switch
                  name={show_in_home.name}
                  checked={show_in_homeV}
                  onChange={() =>
                    autoSwitchChangeHandler(show_in_home.name, !show_in_homeV)
                  }
                />
              </MDBox>
              <MDBox width="80%" ml={0.5}>
                <MDTypography
                  variant="button"
                  fontWeight="regular"
                  color="text"
                >
                  {show_in_home.label}
                </MDTypography>
              </MDBox>
            </MDBox>
          </Grid>
          <Grid item xs={3}>
            <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
              <MDBox mt={0.5}>
                <Switch
                  name={urgent.name}
                  checked={urgentV}
                  onChange={() =>
                    autoSwitchChangeHandler(urgent.name, !urgentV)
                  }
                />
              </MDBox>
              <MDBox width="80%" ml={0.5}>
                <MDTypography
                  variant="button"
                  fontWeight="regular"
                  color="text"
                >
                  {urgent.label}
                </MDTypography>
              </MDBox>
            </MDBox>
          </Grid>
          <Grid item xs={6}>
            <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
              <MDBox mt={0.5}>
                <Switch
                  name={all_amount_required.name}
                  checked={all_amount_requiredV}
                  onChange={() =>
                    autoSwitchChangeHandler(
                      all_amount_required.name,
                      !all_amount_requiredV
                    )
                  }
                />
              </MDBox>
              <MDBox width="80%" ml={0.5}>
                <MDTypography
                  variant="button"
                  fontWeight="regular"
                  color="text"
                >
                  {all_amount_required.label}
                </MDTypography>
              </MDBox>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

// typechecking props for Other
Other.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default Other;
