/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import * as Yup from "yup";
import checkout from "./form";

const {
  formField: {
    ar_pic,
    ar_name,
    en_name,
    tr_name,
    category_id,
    cost,
    benficial_num,
    impl_country_id,
    period,
    period_type,
    status_id,
  },
} = checkout;

const validations = [
  Yup.object().shape({
    [ar_pic.name]: Yup.mixed().required(ar_pic.errorMsg),
  }),
  Yup.object().shape({
    [ar_name.name]: Yup.string().required(ar_name.errorMsg),
  }),
  Yup.object().shape({
    [en_name.name]: Yup.string().required(en_name.errorMsg),
  }),
  Yup.object().shape({
    [tr_name.name]: Yup.string().required(tr_name.errorMsg),
  }),
  Yup.object().shape({
    [category_id.name]: Yup.mixed().required(category_id.errorMsg),
    [cost.name]: Yup.string().required(cost.errorMsg),
    [benficial_num.name]: Yup.string().required(benficial_num.errorMsg),
    [impl_country_id.name]: Yup.mixed().required(impl_country_id.errorMsg),
    [period_type.name]: Yup.mixed().required(period_type.errorMsg),
    [period.name]: Yup.string().required(period.errorMsg),
    [status_id.name]: Yup.mixed().required(status_id.errorMsg),
  }),
];

export default validations;
