/**
=========================================================
* Arakan - v2.1.0
=========================================================

* Product Page: https://www.trakib.com/product/material-dashboard-pro-react
* Copyright 2022 Trakib (https://www.trakib.com)

Coded by www.trakib.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// @material-ui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Arakan components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDAlert from "components/MDAlert";

// Settings page components
import FormField from "layouts/pages/account/components/FormField";

import crudsService from "services/cruds-service";
import dataTableData from "layouts/applications/data-tables/data/dataTableData";
import StatusOnIcon from "@mui/icons-material/Wifi";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { Tooltip, IconButton } from "@mui/material";
import { InputLabel } from "@mui/material";
import MDInput from "components/MDInput";

function LangForm({ project, id }) {
  const [reportFile, setReportFile] = useState(null);
  const [fileState, setFileState] = useState(null);

  const [info, setInfo] = useState({
    name: ""
  });
  const [notification, setNotification] = useState({
    value: false,
    color: "info",
    message: "",
  });

  useEffect(() => {
    console.log(project);

    setInfo({
      name: project.name
    });
    setReportFile(project.report_file);
  }, [project]);

  useEffect(() => {
    if (notification.value === true) {
      setTimeout(() => {
        setNotification({ value: false, color: "info", message: "" });
      }, 5000);
    }
  }, [notification]);

  const [errors, setErrors] = useState({
    name: false
  });

  const changeHandler = (e) => {
    setInfo({
      ...info,
      [e.target.name]: e.target.value,
    });
  };

  const changeReportFileHandler = (e) => {
    setFileState(e.target.files[0]);
    setReportFile(URL.createObjectURL(e.target.files[0]));
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    if (info.name.trim().length === 0) {
      setNotification({
        value: true,
        message: "لم تدخل الاسم",
        color: "error",
      });
      return;
    }
    let fileId;
    if (fileState) fileId = await crudsService.fileUpload(fileState);
    const projectData = {
      name: info.name,
    };

    // call api for update
    await crudsService.updateProject(projectData, id);

    // reset errors
    setErrors({
      firstNameError: false,
      lastNameError: false,
      emailError: false,
    });

    setNotification({
      value: true,
      color: "info",
      message: "تم التعديل بنجاح",
    });
  };

  return (
    <>
      <Card sx={{ overflow: "visible" }}>
        <MDBox p={3}>
          <MDTypography variant="h5">
            {project.languages_code?.name}
          </MDTypography>
        </MDBox>
        <MDBox
          component="form"
          pb={3}
          px={3}
          method="POST"
          onSubmit={submitHandler}
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormField
                onChange={changeHandler}
                label="الاسم"
                name="name"
                value={info[`name`]}
                error={errors.nameError}
              />
            </Grid>
          </Grid>
          <MDBox ml="auto" mt={2} display="flex" justifyContent="flex-end">
            <MDButton
              variant="gradient"
              color="dark"
              size="small"
              type="submit"
            >
              تعديل
            </MDButton>
          </MDBox>
        </MDBox>
      </Card>
      {notification.value === true && (
        <MDAlert color={notification.color} mt="20px">
          <MDTypography variant="body2" color="white">
            {notification.message}
          </MDTypography>
        </MDAlert>
      )}
    </>
  );
}

export default LangForm;
