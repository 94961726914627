/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import checkout from "./form";

const {
  formField: {
    ar_name,
    en_name,
    ar_description,
    en_description,
    tr_description,
    ar_summary,
    en_summary,
    tr_summary,
    tr_name,
    date,
    author_id,
    tags_id,
    // gallery,
    ar_pic,
    en_pic,
    tr_pic,
  },
} = checkout;

const initialValues = {
  [ar_name.name]: "",
  [en_name.name]: "",
  [tr_name.name]: "",
  [ar_description.name]: "",
  [en_description.name]: "",
  [tr_description.name]: "",
  [ar_summary.name]: "",
  [en_summary.name]: "",
  [tr_summary.name]: "",
  [author_id.name]: null,
  [tags_id.name]: [],
  // [gallery.name]: [],
  [date.name]: null,
  [ar_pic.name]: null,
  [en_pic.name]: null,
  [tr_pic.name]: null,
};

export default initialValues;
