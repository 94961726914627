import CrudService from "services/cruds-service";

export const getPermissions = async (id) => {
  try {
    const res = await CrudService.getUserWithPermissions(id);
    const permissions = res.data;
    let jsonPermissions = [];
    permissions.map((permission) => {
      let action = permission.action;
      let subject;
      if (action === "read") action = "view";
      if (action === "update") action = "edit";
      const namePermission = permission.collection.split("_");
      if (
        namePermission.length === 2 &&
        namePermission[0] === "directus" &&
        id === "ead72bf7-b217-49a3-8fc5-0caff2f4d6ef"
      ) {
        subject = namePermission[1];
      } else {
        subject = permission.collection;
      }
      if (
        jsonPermissions.filter(
          (x) => x.subject === subject && x.action === action
        ).length === 0
      )
        jsonPermissions = [...jsonPermissions, { action, subject }];
    });
    return jsonPermissions;
  } catch (err) {
    console.error(err);
    return null;
  }
};
