/**
=========================================================
* Arakan - v2.1.0
=========================================================

* Product Page: https://www.trakib.com/product/material-dashboard-pro-react
* Copyright 2022 Trakib (https://www.trakib.com)

Coded by www.trakib.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useMemo } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Arakan components
import MDBox from "components/MDBox";

// Settings page components
import Header from "./components/Header";
import MainInfo from "./components/MainInfo";
import LangForm from "./components/LangForm";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import crudsService from "services/cruds-service";
import { useNavigate, useParams } from "react-router-dom";
import TimeLineForm from "../edit-project/components/TimeLineForm";
import GalleryForm from "../edit-project/components/galleryForm";

function EditProject() {
  const { id } = useParams();
  const [project, setProject] = useState({
    id: null,
    category: null,
    cost: "",
    benficial_num: "",
    impl_country_id: null,
    period: "",
    period_type: "",
    status: null,
    zakaat_available: false,
    show_in_home: false,
    all_amount_required: false,
    urgent: false,
    time_line_items: [],
    target_segments: [],
  });
  const [translations, setTranslations] = useState([]);
  const [gallery, setGallery] = useState([]);
  const [categories, setCategories] = useState([]);
  const [allStatus, setAllStatus] = useState([]);
  const [countries, setCountries] = useState([]);
  const [timeLineItems, setTimeLineItems] = useState([]);
  const [targetSegments, setTargetSegments] = useState([]);

  const prepareNewArr = (arr, objKey) => {
    let newArr = [];
    for (const key in arr) {
      if (Object.hasOwnProperty.call(arr, key)) {
        const element = arr[key];
        newArr.push({
          id: element.id,
          name: element.translations[0][objKey || "name"],
        });
      }
    }
    // console.log(newArr);
    return newArr;
  };

  const getProjects = async () => {
    const response = await crudsService.getProjectById(id);
    setProject((prevProject) => ({
      ...prevProject,
      id: response.data.id,
      category: response.data.category_id,
      cost: response.data.cost,
      benficial_num: response.data.benficial_num,
      impl_country_id: response.data.impl_country_id,
      period: response.data.period,
      period_type: response.data.period_type,
      status: response.data.status_id,
      zakaat_available: response.data.zakaat_available,
      show_in_home: response.data.show_in_home,
      all_amount_required: response.data.all_amount_required,
      urgent: response.data.urgent,
      time_line_items: response.data.time_line_items,
      target_segments: response.data.target_segments,
      sequence: response.data.sequence,
    }));
    setTranslations(response.data.translations);
    setGallery(response.data.gallery);
  };

  useEffect(() => {
    (async () => {
      if (!id) return;
      getProjects();
      try {
        const categoryData = await crudsService.getChildreensCategories();
        setCategories(prepareNewArr(categoryData.data));
        const statusData = await crudsService.getItems("project_status");
        setAllStatus(prepareNewArr(statusData.data));
        const countriesData = await crudsService.getItems("countries");
        setCountries(prepareNewArr(countriesData.data));
        const timeLineItemsData = await crudsService.getTimeLine();
        setTimeLineItems(prepareNewArr(timeLineItemsData.data, "title"));
        const targetSegmentsData = await crudsService.getItems(
          "target_segment"
        );
        setTargetSegments(prepareNewArr(targetSegmentsData.data));
      } catch (err) {
        console.error(err);
        return null;
      }
    })();
  }, [id]);

  const langForm = useMemo(
    () =>
      translations.map((t) => (
        <Grid
          container
          spacing={3}
          py={2}
          key={Math.random()}
          id={`${t.languages_code?.code.split("-")[0]}-info`}
        >
          <Grid item xl={4}>
            <Header project={t} data={t.pic} />
          </Grid>
          <Grid item xl={8}>
            <LangForm project={t} id={t.id} />
          </Grid>
        </Grid>
      )),
    [translations]
  );

  const getMainInfo = useMemo(() => {
    if (
      categories.length > 0 &&
      allStatus.length > 0 &&
      countries.length > 0 &&
      targetSegments.length > 0 &&
      project
    )
      return (
        <MainInfo
          project={project}
          categories={categories}
          allStatus={allStatus}
          countries={countries}
          targetSegments={targetSegments}
        />
      );
  }, [categories, allStatus, countries, targetSegments, project]);

  const getTimeLineInfo = useMemo(() => {
    if (timeLineItems.length > 0 && project)
      return (
        <TimeLineForm
          project={project}
          timeLineItems={timeLineItems}
          getProjects={getProjects}
        />
      );
  }, [timeLineItems, project]);

  const geGallery = useMemo(() => {
    if (gallery.length > 0 && project)
      return (
        <GalleryForm
          gallery={gallery}
          getProjects={getProjects}
          id={project.id}
        />
      );
  }, [gallery, project]);

  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      <MDBox m={4}>
        {/* <Grid container spacing={1}> */}
        {/* <Grid item xl={12} lg={3}>
            <Sidenav />
          </Grid> */}
        {/* <Grid item xl={12} lg={9}> */}
        <MDBox mb={3}>
          <Grid container spacing={3} justifyContent="center">
            <Grid item xl={12} lg={9}>
              <MDBox mb={3}>
                {langForm}
                <Grid container spacing={3}>
                  <Grid item xl={12}>
                    {getMainInfo}
                  </Grid>
                  <Grid item xl={12}>
                    {getTimeLineInfo}
                  </Grid>
                  <Grid item xl={12}>
                    {geGallery}
                  </Grid>
                </Grid>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        {/* </Grid> */}
        {/* </Grid> */}
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default EditProject;
