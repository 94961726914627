/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// NewUser page components
import { useEffect, useState } from "react";
import FormField from "../FormField";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
// import { setDate } from "date-fns";
import { Autocomplete } from "@mui/material";

import Grid from "@mui/material/Grid";
function TimeLineForm({ formData, lang, timeLineItems = [] }) {
  const { formField, values, setFieldValue, setFieldTouched } = formData;
  const { time_line_items } = formField;
  const { time_line_items: time_line_itemsV } = values;

  // const [timeLineItem, setTimeLineItem] = useState(null);
  // const [date, setDate] = useState(null);

  const [inputs, setInputs] = useState([
    {
      timeLineItem: "",
      date: "",
    },
  ]);

  useEffect(() => {
    if (time_line_itemsV.length > 0) setInputs(time_line_itemsV);
    return () => {
      setInputs([
        {
          timeLineItem: "",
          date: "",
        },
      ]);
    };
  }, [time_line_itemsV]);

  const handleAddInput = () => {
    setInputs([
      ...inputs,
      {
        timeLineItem: "",
        date: "",
      },
    ]);
  };

  const handleChange = (name, index, v) => {
    console.log(name, index, v);
    let onChangeValue = [...inputs];
    onChangeValue[index][name] = v;
    setInputs(onChangeValue);
    setFieldValue(time_line_items.name, onChangeValue);
    setFieldTouched(time_line_items.name, onChangeValue);
  };

  const handleDeleteInput = (index) => {
    const newArray = [...inputs];
    newArray.splice(index, 1);
    setInputs(newArray);
    setFieldValue(time_line_items.name, newArray);
    setFieldTouched(time_line_items.name, newArray);
  };

  // const handleAddTimeLine = () => {
  //   console.log(timeLineItem, date);
  //   if (timeLineItem && date) {
  //     let y = time_line_itemsV;
  //     y.push({
  //       timeLineItem: timeLineItem,
  //       date: date,
  //     });
  //     setFieldValue(time_line_items.name, y);
  //     setFieldTouched(time_line_items.name, y);
  //     setTimeLineItem(null);
  //     setDate(null);
  //     y = [];
  //   }
  // };

  // const deleteItem = (b) => {
  //   let r = [];
  //   time_line_itemsV.forEach((element, index) => {
  //     if (index !== b) r.push(element);
  //   });
  //   setFieldValue(time_line_items.name, r);
  //   setFieldTouched(time_line_items.name, r);
  // };

  // const getItems = () => {
  //   return time_line_itemsV.map((t, i) => (
  //     <MDBox mt={1.625} mx={10} display="flex" justifyContent="space-between">
  //       <MDTypography key={Math.random()} variant="body2">{`${i + 1} - ${
  //         t.date
  //       } - ${t.timeLineItem.name}`}</MDTypography>
  //       <MDTypography
  //         key={Math.random()}
  //         variant="body2"
  //         color="error"
  //         onClick={() => {
  //           deleteItem(i);
  //         }}
  //       >
  //         x
  //       </MDTypography>
  //     </MDBox>
  //   ));
  // };

  return (
    <MDBox>
      <MDTypography variant="h5" fontWeight="bold">
        {lang?.name}
      </MDTypography>
      {/* <MDBox mt={1.625}>{getItems()}</MDBox> */}
      {/* <MDBox mt={1.625} display="flex"> */}
      {/* <Grid container spacing={3}> */}
      {/* <Grid item xs={5}>
            <Autocomplete
              label="الخطة الزمنية"
              options={timeLineItems}
              name="timeLineItem"
              renderOption={(props, option) => (
                <li {...props} key={option.id}>
                  {option.data ? option.data.name : option.name}
                </li>
              )}
              getOptionLabel={(option) => {
                if (option.data) {
                  if (option.data.name) return option.data.name;
                } else {
                  if (option) {
                    if (option.name) return option.name;
                  }
                }
                return "";
              }}
              isOptionEqualToValue={(option, item) =>
                option === item || option.id === item.id
              }
              renderInput={(params) => (
                <MDInput
                  {...params}
                  variant="standard"
                  label="timeLineItem"
                  label="الخطة الزمنية"
                />
              )}
              onChange={(e, v) => {
                console.log(v);
                setTimeLineItem(v);
              }}
              value={timeLineItem}
            />
          </Grid>
          <Grid item xs={5}>
            <FormField
              type="date"
              label="التاريخ"
              name="date"
              value={date}
              onChange={(e, v) => {
                console.log(e.target.value);
                setDate(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={2}> 
            <MDButton
              variant="gradient"
              color="light"
              onClick={handleAddTimeLine}
              sx={{ mx: 1 }}
            >
              إضافة
            </MDButton>
          </Grid>*/}
      {inputs.map((item, index) => (
        <Grid container spacing={3}>
          <Grid item xs={5}>
            <Autocomplete
              label="الخطة الزمنية"
              options={timeLineItems}
              name="timeLineItem"
              renderOption={(props, option) => (
                <li {...props} key={option.id}>
                  {option.data ? option.data.name : option.name}
                </li>
              )}
              getOptionLabel={(option) => {
                if (option.data) {
                  if (option.data.name) return option.data.name;
                } else {
                  if (option) {
                    if (option.name) return option.name;
                  }
                }
                return "";
              }}
              isOptionEqualToValue={(option, item) =>
                option === item || option.id === item.id
              }
              renderInput={(params) => (
                <MDInput
                  {...params}
                  variant="standard"
                  label="timeLineItem"
                  label="الخطة الزمنية"
                />
              )}
              onChange={(event, v) => handleChange("timeLineItem", index, v)}
              // onChange={(e, v) => {
              //   console.log(v);
              //   setTimeLineItem(v);
              // }}
              value={item.timeLineItem}
            />
          </Grid>
          <Grid item xs={5}>
            <FormField
              type="date"
              label="التاريخ"
              name="date"
              onChange={(event) =>
                handleChange("date", index, event.target.value)
              }
              value={item.date}
            />
          </Grid>
          <Grid item xs={1}>
            {inputs.length > 1 && (
              <MDButton onClick={() => handleDeleteInput(index)} size="large">
                -
              </MDButton>
            )}
          </Grid>
          <Grid item xs={1}>
            {index === inputs.length - 1 && (
              <MDButton onClick={() => handleAddInput()} size="large">
                +
              </MDButton>
            )}
          </Grid>
        </Grid>
      ))}

      {/* <div className="body"> {JSON.stringify(inputs)} </div> */}
      {/* </Grid> */}
      {/* </MDBox> */}
    </MDBox>
  );
}

// typechecking props for TimeLineForm
TimeLineForm.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default TimeLineForm;
