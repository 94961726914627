/**
=========================================================
* Arakan - v2.1.0
=========================================================

* Product Page: https://www.trakib.com/product/material-dashboard-pro-react
* Copyright 2022 Trakib (https://www.trakib.com)

Coded by www.trakib.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Arakan components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAlert from "components/MDAlert";

// Arakan examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import MDButton from "components/MDButton";
import { Tooltip, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import AuthService from "services/auth-service";
import CrudService from "services/cruds-service";
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { AbilityContext } from "Can";
import { useAbility } from "@casl/react";
import i18n from "i18n";

function Tags() {
  let { state } = useLocation();
  const { t } = i18n;
  let { pathname } = useLocation();

  const ability = useAbility(AbilityContext);
  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [meta, setMeta] = useState(null);
  const [page, setPage] = useState(1);
  const [sortVal, setSortVal] = useState(null);
  const [sortUrl, setSortUrl] = useState("");
  // const [isAdmin, setIsAdmin] = useState(false);
  const [filterUrl, setFilterUrl] = useState(null);
  const [notification, setNotification] = useState({
    value: false,
    text: "",
  });

  const navigate = useNavigate();

  const getTags = async (filUrl) => {
    const URL = filUrl ? `${filUrl}&page=${page}` : `&page=${page}`;
    const response = await CrudService.getTags(URL, true);
    setData(response.data);
    setMeta(response.meta);
  };

  // useEffect(() => {
  //   (async () => {
  //     const { data } = await AuthService.getProfile();
  //     setIsAdmin(data.role.name === "Administrator");
  //   })();
  // }, []);

  useEffect(() => {
    getTags(`${filterUrl || ""}${sortUrl}`);
  }, [page]);

  useEffect(() => {
    if (sortVal) {
      setSortUrl(`&sort=${sortVal.desc ? "-" : "+"}${sortVal.id}`);
    }
  }, [sortVal]);

  useEffect(() => {
    if (page === 1) getTags(`${filterUrl || ""}${sortUrl}`);
    else if (page !== 1) setPage(1);
  }, [filterUrl, sortUrl]);

  useEffect(() => {
    setTableData(getRows(data));
  }, [data]);

  useEffect(() => {
    if (!state) return;
    setNotification({
      value: state.value,
      text: state.text,
    });
  }, [state]);

  useEffect(() => {
    if (notification.value === true) {
      setTimeout(() => {
        setNotification({
          value: false,
          text: "",
        });
      }, 5000);
    }
  }, [notification]);

  const clickAddHandler = () => {
    console.log(`/articleManagement/tags/add/tag`);
    navigate(`/articleManagement/tags/add/tag`);
  };

  const clickEditHandler = (id) => {
    navigate(`/articleManagement/tags/update/tag/${id}`);
  };

  const clickDeleteHandler = async (e, id) => {
    try {
      if (!window.confirm(t("confirmDelete"))) {
        e.nativeEvent.stopImmediatePropagation();
      } else {
        await CrudService.deleteTag(id);
        // the delete does not send a response
        // so I need to get again the tags to set it and this way the table gets updated -> it goes to the useEffect with data dependecy
        const response = await CrudService.getTags(filterUrl, true);
        setData(response.data);
        setNotification({
          value: true,
          text: t("deleteSuccessfully"),
        });
      }
    } catch (err) {
      // it sends error is the category is associated with an item
      console.error(err);
      if (err.hasOwnProperty("errors")) {
        setNotification({
          value: true,
          text: err.errors[0].title,
        });
      }
      return null;
    }
  };

  const getRows = (info) => {
    let updatedInfo = info.map((row) => {
      return {
        id: row.id,
        name: row.translations[0].name,
        color: row.color,
        articles: row.articles.length,
      };
    });
    return updatedInfo;
  };

  const dataTableData = {
    columns: [
      { Header: t("name"), accessor: "name", width: "25%" },
      {
        Header: t("color"),
        accessor: "color",
        width: "25%",
        Cell: (info) => {
          return (
            <>
              <MDBox
                sx={{
                  backgroundColor: info.cell.row.values.color,
                  borderRadius: 16,
                }}
                px={2}
              >
                <MDTypography variant="caption" color="white">
                  {info.cell.row.values.name}
                </MDTypography>
              </MDBox>
            </>
          );
        },
      },
      {
        Header: t("actions"),
        disableSortBy: true,
        accessor: "",
        Cell: (info) => {
          if (info.cell.row.original.articles === 0)
            return (
              <MDBox display="flex" alignItem="center">
                {/* {(ability.can("delete", "tags") || isAdmin) && ( */}
                <Tooltip
                  title={t("deleteTag")}
                  onClick={(e) =>
                    clickDeleteHandler(e, info.cell.row.original.id)
                  }
                >
                  <IconButton>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
                {/* )} */}
              </MDBox>
            );
          else return null;
        },
      },
    ],

    rows: tableData,
  };

  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      {notification.value && (
        <MDAlert color="info" my="20px">
          <MDTypography variant="body2" color="white">
            {notification.text}
          </MDTypography>
        </MDAlert>
      )}
      <MDBox pt={6} pb={3}>
        <MDBox mb={3}>
          <Card>
            <MDBox
              p={3}
              lineHeight={1}
              display="flex"
              justifyContent="space-between"
            >
              <MDTypography variant="h5" fontWeight="medium">
                {t(`tagManagement`)}
              </MDTypography>
              {/* {(ability.can("create", "tags") || isAdmin) && ( */}
              <MDButton
                variant="gradient"
                color="dark"
                size="small"
                type="submit"
                onClick={clickAddHandler}
              >
                + {t("addTag")}
              </MDButton>
              {/* )} */}
            </MDBox>
            <DataTable
              table={dataTableData}
              meta={meta}
              setPage={setPage}
              setSortVal={setSortVal}
              pageNum={page}
              isSorted={false}
            />
          </Card>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Tags;
