/**
=========================================================
* Muwafiq - v2.1.0
=========================================================

* Product Page: https://www.trakib.com/product/material-dashboard-pro-react
* Copyright 2022 Trakib (https://www.trakib.com)

Coded by www.trakib.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import { Autocomplete, Switch } from "@mui/material";

// Muwafiq components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
// import { useParams } from "react-router-dom";

import CrudService from "services/cruds-service";
import AuthService from "services/auth-service";
import crudsService from "services/cruds-service";

import FormField from "layouts/applications/wizard/components/FormField";
// import console from "node:console";
import i18n from "i18n";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

const FilterProject = ({ setFilterUrl }) => {
  const [category, setCategory] = useState(null);
  const [status, setStatus] = useState(null);
  const [country, setCountry] = useState(null);

  const [categories, setCategories] = useState([]);
  const [allStatus, setAllStatus] = useState([]);
  const [countries, setCountries] = useState([]);

  const { t } = i18n;

  const [info, setInfo] = useState({
    cost: "",
    benficial_num: "",
    period: "",
    urgent: "",
    category_id: null,
    impl_country_id: null,
    status_id: null,
    zakaat_available: false,
    show_in_home: false,
    all_amount_required: false,
    target_segments: [],
  });

  const prepareNewArr = (arr, objKey) => {
    let newArr = [];
    for (const key in arr) {
      if (Object.hasOwnProperty.call(arr, key)) {
        const element = arr[key];
        newArr.push({
          id: element.id,
          name: element.translations[0][objKey || "name"],
        });
      }
    }
    return newArr;
  };

  useEffect(() => {
    (async () => {
      try {
        const categoryData = await crudsService.getChildreensCategories();
        setCategories(prepareNewArr(categoryData.data));
        const statusData = await crudsService.getItems("project_status");
        setAllStatus(prepareNewArr(statusData.data));
        const countriesData = await crudsService.getItems("countries");
        setCountries(prepareNewArr(countriesData.data));
      } catch (err) {
        console.error(err);
        return null;
      }
    })();
  }, []);

  const changeHandler = (e) => {
    console.log(e.target.name, e.target.value);
    setInfo({
      ...info,
      [e.target.name]: e.target.value,
    });
  };

  const autoSwitchChangeHandler = (name, v) => {
    setInfo({
      ...info,
      [name]: v,
    });
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      let str = "";
      const values = {
        cost: info.cost,
        benficial_num: info.benficial_num,
        period: info.period,
        urgent: info.urgent,
        category_id: category?.id,
        impl_country_id: country?.id,
        status_id: status?.id,
        zakaat_available: info.zakaat_available,
        show_in_home: info.show_in_home,
        all_amount_required: info.all_amount_required,
      };
      const keys = Object.keys(values);
      keys.forEach((element) => {
        if (values[element] && values[element] !== []) {
          str += `&filter[${element}][_eq]=${values[element]}`;
        }
      });

      setFilterUrl(str);
    } catch (err) {
      console.log({ error: true, textError: err.message });
      return null;
    }
  };

  return (
    <MDBox
      component="form"
      method="POST"
      onSubmit={submitHandler}
      encType="multipart/form-data"
      px={5}
    >
      <MDBox display="flex" flexDirection="column" px={3} my={4}>
        <MDBox display="flex" flexDirection="column">
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <FormField
                onChange={changeHandler}
                label={t("cost")}
                name="cost"
                value={info.cost}
                placeholder="cost"
              />
            </Grid>
            <Grid item xs={3}>
              <FormField
                onChange={changeHandler}
                label="عدد المستفيدين"
                name="benficial_num"
                value={info.benficial_num}
                placeholder="benficial_num"
              />
            </Grid>
            <Grid item xs={3}>
              <FormField
                onChange={changeHandler}
                label="المدة"
                name="period"
                value={info.period}
                placeholder={"period"}
              />
            </Grid>
            <Grid item xs={3}>
              <Autocomplete
                variant="outlined"
                options={countries}
                name="impl_country_id"
                renderOption={(props, option) => (
                  <li {...props} key={option.id}>
                    {option.data ? option.data.name : option.name}
                  </li>
                )}
                getOptionLabel={(option) => {
                  if (option.data) {
                    if (option.data.name) return option.data.name;
                  } else {
                    if (option) {
                      if (option.name) return option.name;
                    }
                  }
                  return "";
                }}
                isOptionEqualToValue={(option, item) =>
                  option === item || option?.id === item?.id
                }
                renderInput={(params) => (
                  <MDInput
                    variant="outlined"
                    {...params}
                    //variant="standard"
                    label="بلد التنفيذ"
                  />
                )}
                onChange={(e, v) => setCountry(v)}
                value={country}
              />
            </Grid>
            <Grid item xs={3}>
              <Autocomplete
                options={categories}
                name="category_id"
                renderOption={(props, option) => (
                  <li {...props} key={option.id}>
                    {option.data ? option.data.name : option.name}
                  </li>
                )}
                getOptionLabel={(option) => {
                  if (option.data) {
                    if (option.data.name) return option.data.name;
                  } else {
                    if (option) {
                      if (option.name) return option.name;
                    }
                  }
                  return "";
                }}
                isOptionEqualToValue={(option, item) =>
                  option === item || option?.id === item?.id
                }
                variant="outlined"
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    //variant="standard"
                    label="التصنيف"
                  />
                )}
                onChange={(e, v) => setCategory(v)}
                value={category}
              />
            </Grid>
            <Grid item xs={3}>
              <Autocomplete
                options={allStatus}
                name="status_id"
                renderOption={(props, option) => (
                  <li {...props} key={option.id}>
                    {option.data ? option.data.name : option.name}
                  </li>
                )}
                getOptionLabel={(option) => {
                  if (option.data) {
                    if (option.data.name) return option.data.name;
                  } else {
                    if (option) {
                      if (option.name) return option.name;
                    }
                  }
                  return "";
                }}
                isOptionEqualToValue={(option, item) =>
                  option === item || option?.id === item?.id
                }
                renderInput={(params) => (
                  <MDInput
                    variant="outlined"
                    {...params}
                    //variant="standard"
                    label="الحالة"
                  />
                )}
                onChange={(e, v) => setStatus(v)}
                value={status}
              />
            </Grid>
            <Grid item xs={3}>
              <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
                <MDBox mt={0.5}>
                  <Switch
                    // onChange={changeHandler}
                    name="zakaat_available"
                    checked={info.zakaat_available}
                    onChange={() =>
                      autoSwitchChangeHandler(
                        "zakaat_available",
                        !info.zakaat_available
                      )
                    }
                  />
                </MDBox>
                <MDBox width="80%" ml={0.5}>
                  <MDTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                  >
                    تجوز الزكاة
                  </MDTypography>
                </MDBox>
              </MDBox>
            </Grid>
            <Grid item xs={3}>
              <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
                <MDBox mt={0.5}>
                  <Switch
                    name="show_in_home"
                    checked={info.show_in_home}
                    // onChange={changeHandler}
                    onChange={() =>
                      autoSwitchChangeHandler(
                        "show_in_home",
                        !info.show_in_home
                      )
                    }
                  />
                </MDBox>
                <MDBox width="80%" ml={0.5}>
                  <MDTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                  >
                    العرض في الرئيسية
                  </MDTypography>
                </MDBox>
              </MDBox>
            </Grid>
            <Grid item xs={3}>
              <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
                <MDBox mt={0.5}>
                  <Switch
                    name="urgent"
                    checked={info.urgent}
                    // onChange={changeHandler}
                    onChange={() =>
                      autoSwitchChangeHandler("urgent", !info.urgent)
                    }
                  />
                </MDBox>

                <MDBox width="80%" ml={0.5}>
                  <MDTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                  >
                    عاجل
                  </MDTypography>
                </MDBox>
              </MDBox>
            </Grid>
            <Grid item xs={6}>
              <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
                <MDBox mt={0.5}>
                  <Switch
                    name="all_amount_required"
                    checked={info.all_amount_required}
                    // onChange={changeHandler}
                    onChange={() =>
                      autoSwitchChangeHandler(
                        "all_amount_required",
                        !info.all_amount_required
                      )
                    }
                  />
                </MDBox>
                <MDBox width="80%" ml={0.5}>
                  <MDTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                  >
                    قيمة المشروع بالكامل مطلوبة(مشاريع خاصة)
                  </MDTypography>
                </MDBox>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox ml="auto" mt={4} mb={2} display="flex" justifyContent="flex-end">
          <MDButton variant="gradient" color="dark" size="small" type="submit">
            {t("search")}
          </MDButton>
        </MDBox>
      </MDBox>
    </MDBox>
  );
};

export default FilterProject;
