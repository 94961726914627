/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// NewUser page components
import FormField from "../FormField";
import { useEffect, useState } from "react";
import { InputLabel } from "@mui/material";
import MDInput from "components/MDInput";
import MDAvatar from "components/MDAvatar";
import MDButton from "components/MDButton";

function LangForm({ formData, lang }) {
  const { formField, values, errors, touched, setFieldValue, setFieldTouched } =
    formData;
  const field = lang.code?.split("-")[0];

  ////

  const [inputs, setInputs] = useState([{ text: "" }]);

  useEffect(() => {
    if (values[formField[`${field}_targets`].name] !== "")
      setInputs(values[formField[`${field}_targets`].name]);
    return () => {
      setInputs([{ text: "" }]);
    };
  }, [values[formField[`${field}_targets`].name]]);

  const handleAddInput = () => {
    setInputs([...inputs, { text: "" }]);
  };

  const handleChange = (event, index) => {
    let { name, value } = event.target;
    let onChangeValue = [...inputs];
    onChangeValue[index][name] = value;
    setInputs(onChangeValue);
    setFieldValue(formField[`${field}_targets`].name, onChangeValue);
    setFieldTouched(formField[`${field}_targets`].name, onChangeValue);
  };

  const handleDeleteInput = (index) => {
    const newArray = [...inputs];
    newArray.splice(index, 1);
    setInputs(newArray);
    setFieldValue(formField[`${field}_targets`].name, newArray);
    setFieldTouched(formField[`${field}_targets`].name, newArray);
  };

  ////

  // const [resultVideoUrl, setResultVideoUrl] = useState(null);
  // const [reportFileUrl, setReportFileUrl] = useState(null);
  // const [promoVideoUrl, setPromoVideoUrl] = useState(null);

  // const changeResultVideoHandler = (e) => {
  //   // const formData = new FormData();
  //   // formData.append("attachment", e.target.files[0]);
  //   // setFieldValue(formField[`${field}_result_video`].name, formData);
  //   // setFieldTouched(formField[`${field}_result_video`].name, formData);
  //   setFieldValue(formField[`${field}_result_video`].name, e.target.files[0]);
  //   setFieldTouched(formField[`${field}_result_video`].name, e.target.files[0]);
  //   setResultVideoUrl(URL.createObjectURL(e.target.files[0]));
  // };

  // const changeReportFileHandler = (e) => {
  //   // const formData = new FormData();
  //   // formData.append("attachment", e.target.files[0]);
  //   // setFieldValue(formField[`${field}_report_file`].name, formData);
  //   // setFieldTouched(formField[`${field}_report_file`].name, formData);
  //   setFieldValue(formField[`${field}_report_file`].name, e.target.files[0]);
  //   setFieldTouched(formField[`${field}_report_file`].name, e.target.files[0]);
  //   setReportFileUrl(URL.createObjectURL(e.target.files[0]));
  // };

  // const changePromoVideoHandler = (e) => {
  //   // const formData = new FormData();
  //   // formData.append("attachment", e.target.files[0]);
  //   // setFieldValue(formField[`${field}_promo_video`].name, formData);
  //   // setFieldTouched(formField[`${field}_promo_video`].name, formData);
  //   setFieldValue(formField[`${field}_promo_video`].name, e.target.files[0]);
  //   setFieldTouched(formField[`${field}_promo_video`].name, e.target.files[0]);
  //   setPromoVideoUrl(URL.createObjectURL(e.target.files[0]));
  // };
  // name, summary,desc,report_desc,result_video,report_file,promo_video

  return (
    <MDBox>
      <MDTypography variant="h5" fontWeight="bold">
        {lang?.name}
      </MDTypography>
      <MDBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormField
              type={formField[`${field}_name`]?.type}
              label={formField[`${field}_name`]?.label}
              name={formField[`${field}_name`]?.name}
              value={values[`${field}_name`]}
              placeholder={formField[`${field}_name`]?.placeholder}
              error={errors[`${field}_name`] && touched[`${field}_name`]}
              success={
                values[`${field}_name`]?.length > 0 && !errors[`${field}_name`]
              }
            />
          </Grid>
          <Grid item xs={12}>
            <FormField
              type={formField[`${field}_summary`]?.type}
              label={formField[`${field}_summary`]?.label}
              name={formField[`${field}_summary`]?.name}
              value={values[`${field}_summary`]}
              placeholder={formField[`${field}_summary`]?.placeholder}
              error={errors[`${field}_summary`] && touched[`${field}_summary`]}
              success={
                values[`${field}_summary`]?.length > 0 &&
                !errors[`${field}_summary`]
              }
            />
          </Grid>
          <Grid item xs={12}>
            <FormField
              multiline
              rows={5}
              type={formField[`${field}_desc`]?.type}
              label={formField[`${field}_desc`]?.label}
              name={formField[`${field}_desc`]?.name}
              value={values[`${field}_desc`]}
              placeholder={formField[`${field}_desc`]?.placeholder}
              error={errors[`${field}_desc`] && touched[`${field}_desc`]}
              success={
                values[`${field}_desc`]?.length > 0 && !errors[`${field}_desc`]
              }
            />
          </Grid>
          <Grid item xs={12}>
            <FormField
              type={formField[`${field}_promo_video_url`]?.type}
              label={formField[`${field}_promo_video_url`]?.label}
              name={formField[`${field}_promo_video_url`]?.name}
              value={values[`${field}_promo_video_url`]}
              placeholder={formField[`${field}_promo_video_url`]?.placeholder}
              error={
                errors[`${field}_promo_video_url`] &&
                touched[`${field}_promo_video_url`]
              }
              success={
                values[`${field}_promo_video_url`]?.length > 0 &&
                !errors[`${field}_promo_video_url`]
              }
            />
          </Grid>
          {/* <Grid item xs={12}>
            <FormField
              multiline
              rows={5}
              type={formField[`${field}_targets`]?.type}
              label={formField[`${field}_targets`]?.label}
              name={formField[`${field}_targets`]?.name}
              value={values[`${field}_targets`]}
              placeholder={formField[`${field}_targets`]?.placeholder}
              error={errors[`${field}_targets`] && touched[`${field}_targets`]}
              success={
                values[`${field}_targets`]?.length > 0 &&
                !errors[`${field}_targets`]
              }
            />
          </Grid> */}
          <Grid item xs={12}>
            <MDTypography variant="h6">الأهداف</MDTypography>
            {inputs.map((item, index) => (
              <Grid container spacing={3}>
                <Grid item xs={10}>
                  <FormField
                    name="text"
                    type="text"
                    value={item.text}
                    onChange={(event) => handleChange(event, index)}
                  />
                </Grid>
                <Grid item xs={1}>
                  {inputs.length > 1 && (
                    <MDButton
                      onClick={() => handleDeleteInput(index)}
                      size="large"
                    >
                      -
                    </MDButton>
                  )}
                </Grid>
                <Grid item xs={1}>
                  {index === inputs.length - 1 && (
                    <MDButton onClick={() => handleAddInput()} size="large">
                      +
                    </MDButton>
                  )}
                </Grid>
              </Grid>
            ))}

            {/* <div className="body"> {JSON.stringify(inputs)} </div> */}
          </Grid>
          {/* <Grid item xs={12}>
            <FormField
              multiline
              rows={5}
              type={formField[`${field}_report_desc`]?.type}
              label={formField[`${field}_report_desc`]?.label}
              name={formField[`${field}_report_desc`]?.name}
              value={values[`${field}_report_desc`]}
              placeholder={formField[`${field}_report_desc`]?.placeholder}
              error={
                errors[`${field}_report_desc`] &&
                touched[`${field}_report_desc`]
              }
              success={
                values[`${field}_report_desc`]?.length > 0 &&
                !errors[`${field}_report_desc`]
              }
            />
          </Grid> */}
          {/* <Grid item xs={6}>
            <MDBox
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <MDBox mt={2} display="flex" flexDirection="column">
                <InputLabel id="demo-simple-select-label">
                  {formField[`${field}_result_video`]?.label}
                </InputLabel>
                <MDInput
                  type="file"
                  name="attachment"
                  onChange={changeResultVideoHandler}
                  id="file-input"
                  accept="image/*"
                  sx={{ mt: "16px" }}
                ></MDInput>
              </MDBox>

              {resultVideoUrl && (
                <MDBox ml={4} mt={2}>
                  <MDAvatar
                    src={resultVideoUrl}
                    alt="profile-image"
                    size="xxl"
                    shadow="sm"
                  />
                </MDBox>
              )}
            </MDBox>
          </Grid> */}
          {/* <Grid item xs={6}>
            <MDBox
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <MDBox mt={2} display="flex" flexDirection="column">
                <InputLabel id="demo-simple-select-label">
                  {formField[`${field}_report_file`]?.label}
                </InputLabel>
                <MDInput
                  type="file"
                  name="attachment"
                  onChange={changeReportFileHandler}
                  id="file-input"
                  accept="image/*"
                  sx={{ mt: "16px" }}
                ></MDInput>
              </MDBox>

              {reportFileUrl && (
                <MDBox ml={4} mt={2}>
                  <MDAvatar
                    src={reportFileUrl}
                    alt="profile-image"
                    size="xxl"
                    shadow="sm"
                  />
                </MDBox>
              )}
            </MDBox>
          </Grid> */}
          {/* <Grid item xs={6}>
            <MDBox
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <MDBox mt={2} display="flex" flexDirection="column">
                <InputLabel id="demo-simple-select-label">
                  {formField[`${field}_promo_video`]?.label}
                </InputLabel>
                <MDInput
                  type="file"
                  name="attachment"
                  onChange={changePromoVideoHandler}
                  id="file-input"
                  accept="image/*"
                  sx={{ mt: "16px" }}
                ></MDInput>
              </MDBox>

              {promoVideoUrl && (
                <MDBox ml={4} mt={2}>
                  <MDAvatar
                    src={promoVideoUrl}
                    alt="profile-image"
                    size="xxl"
                    shadow="sm"
                  />
                </MDBox>
              )}
            </MDBox>
          </Grid> */}
        </Grid>
      </MDBox>
    </MDBox>
  );
}

// typechecking props for LangForm
LangForm.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default LangForm;
