import HttpService from "./http.service";

class CrudService {
  _limit = 5;

  setLimit = (l) => {
    this._limit = l;
  };

  getLimit = () => {
    return this._limit;
  };

  getLanguages = async () => {
    let languagesEndpoint = `items/languages`;
    return await HttpService.get(languagesEndpoint);
  };

  getPages = async () => {
    let pagesEndpoint = `items/pages`;
    return await HttpService.get(pagesEndpoint);
  };

  // users requests
  imageUpload = async (formData, id) => {
    const imageUpdate = `files`;
    const { data } = await HttpService.post(imageUpdate, formData, true);
    const payload = { avatar: data.id };
    await this.updateUser(payload, id);
    return data;
  };

  // project lang image requests
  uploadAndUpdateProjectImage = async (formData, id) => {
    const imageUpdate = `files`;
    const { data } = await HttpService.post(imageUpdate, formData, true);
    const payload = { pic: data.id };
    this.updateProject(payload, id);
    return data;
  };

  uploadAndUpdateArticleImage = async (formData, id) => {
    const imageUpdate = `files`;
    const { data } = await HttpService.post(imageUpdate, formData, true);
    const payload = { image: data.id };
    this.updateArticle(payload, id);
    return data;
  };

  uploadAndUpdateSliderImage = async (formData, id) => {
    const imageUpdate = `files`;
    const { data } = await HttpService.post(imageUpdate, formData, true);
    const payload = { image: data.id };
    this.updateSlider(payload, id);
    return data;
  };

  updateProject = async (payload, id) => {
    const imageUpdate = `items/projects_translations/${id}`;
    const { data } = await HttpService.patch(imageUpdate, payload);
    return data;
  };

  updateArticle = async (payload, id) => {
    const imageUpdate = `items/article_translations/${id}`;
    const { data } = await HttpService.patch(imageUpdate, payload);
    return data;
  };

  updateProjectInfo = async (payload, id) => {
    const projectUpdate = `items/projects/${id}`;
    const { data } = await HttpService.patch(projectUpdate, payload);
    return data;
  };

  updateArticleInfo = async (payload, id) => {
    const projectUpdate = `items/article/${id}`;
    const { data } = await HttpService.patch(projectUpdate, payload);
    return data;
  };

  updateSlider = async (payload, id) => {
    const imageUpdate = `items/settings_translations/${id}`;
    const { data } = await HttpService.patch(imageUpdate, payload);
    return data;
  };

  updateSliderInfo = async (payload, id) => {
    const sliderUpdate = `items/settings/${id}`;
    const { data } = await HttpService.patch(sliderUpdate, payload);
    return data;
  };

  removeProjectTargetSegment = async (id) => {
    const projectUpdate = `items/projects_target_segment/${id}`;
    const { data } = await HttpService.delete(projectUpdate);
    return data;
  };

  addProjectTargetSegment = async (payload) => {
    const projectUpdate = `items/projects_target_segment`;
    const { data } = await HttpService.post(projectUpdate, payload);
    return data;
  };

  removeArticleTags = async (id) => {
    const articleUpdate = `items/article_tags/${id}`;
    const { data } = await HttpService.delete(articleUpdate);
    return data;
  };

  addArticleTags = async (payload) => {
    const articleUpdate = `items/article_tags`;
    const { data } = await HttpService.post(articleUpdate, payload);
    return data;
  };

  // getUsers = async (filter) => {
  //   let usersEndpoint = `users?fields=*,role.name,role.id&meta=*&limit=${this._limit}`;
  //   if (filter) usersEndpoint += filter;
  //   return await HttpService.get(usersEndpoint);
  // };

  getCustomers = async (filter) => {
    // let filterVal = {
    //   _or: [
    //     { role: { id: { _eq: "222e841a-1ee9-482d-a8da-129cdb7fc05d" } } },
    //     { role: { id: { _null: true } } },
    //   ],
    // };

    let usersEndpoint = `users?fields=*,role.name,role.id&filter[role][id][_eq]=222e841a-1ee9-482d-a8da-129cdb7fc05d&meta=*&limit=${this._limit}`;
    if (filter) usersEndpoint += filter;
    return await HttpService.get(usersEndpoint);
  };

  getEmployees = async (filter) => {
    let usersEndpoint = `users?fields=*,role.name,role.id&filter[role][id][_neq]=222e841a-1ee9-482d-a8da-129cdb7fc05d&meta=*&limit=${this._limit}`;
    if (filter) usersEndpoint += filter;
    return await HttpService.get(usersEndpoint);
  };

  // getResUsers = async (filter) => {
  //   let usersEndpoint = `users?fields=*,role.name,role.id&filter[role][_neq]=c169fb14-7242-4ccb-8b15-e8077d779177`;
  //   if (filter) usersEndpoint += filter;
  //   return await HttpService.get(usersEndpoint);
  // };

  deleteUser = async (id) => {
    const endpoint = `users/${id}`;
    return await HttpService.delete(endpoint);
  };

  createUser = async (payload) => {
    const endpoint = `users`;
    return await HttpService.post(endpoint, payload);
  };

  getUser = async (id) => {
    const endpoint = `users/${id}?fields=*,role.name,role.id`;
    return await HttpService.get(endpoint);
  };

  getUserWithPermissions = async (id) => {
    const endpoint = `permissions?fields=*&filter[role][_eq]=${id}`;
    return await HttpService.get(endpoint);
  };

  updateUser = async (payload, id) => {
    const endpoint = `users/${id}`;
    return await HttpService.patch(endpoint, payload);
  };

  //projects
  getProjects = async (url, isLimited = true) => {
    let projectsEndpoint = `items/projects?fields=*,translations.*,target_segments.target_segment_id.translations.name,gallery.*,impl_country_id.translations.name,status_id.translations.name,status_id.color,category_id.translations.name&filter[category_id][_nin]=1,22&meta=*${url}`;
    if (isLimited) projectsEndpoint += `&limit=${this._limit}`;
    return await HttpService.get(projectsEndpoint);
  };

  //projects
  getSpecialProjects = async (url, isLimited = true) => {
    let projectsEndpoint = `items/projects?fields=*,translations.*&filter[category_id][_in]=1,22&meta=*${url}`;
    if (isLimited) projectsEndpoint += `&limit=${this._limit}`;
    return await HttpService.get(projectsEndpoint);
  };

  //projects by id
  getProjectById = async (id) => {
    const projectsEndpoint = `items/projects/${id}?fields=*,translations.*,translations.languages_code.name,translations.languages_code.code,time_line_items.date,time_line_items.id,time_line_items.timeline_id.id,time_line_items.timeline_id.translations.*,target_segments.id,target_segments.target_segment_id.id,target_segments.target_segment_id.translations.*,gallery.id,gallery.directus_files_id.id,impl_country_id.translations.name,impl_country_id.id,status_id.id,status_id.translations.name,status_id.color,category_id.id,category_id.translations.name`;
    return await HttpService.get(projectsEndpoint);
  };

  //articles
  getArticles = async (url, isLimited = true) => {
    let projectsEndpoint = `items/article?fields=*,translations.*,tags.tags_id.translations.name,gallery.*,author_id.translations.*&meta=*${url}`;
    if (isLimited) projectsEndpoint += `&limit=${this._limit}`;
    return await HttpService.get(projectsEndpoint);
  };

  //article by id
  getArticleById = async (id) => {
    const projectsEndpoint = `items/article/${id}?fields=*,translations.*,translations.languages_code.name,translations.languages_code.code,tags.id,tags.tags_id.id,tags.tags_id.translations.*,gallery.id,gallery.directus_files_id.id`;
    return await HttpService.get(projectsEndpoint);
  };

  //contents
  getContents = async (url, isLimited = true) => {
    let projectsEndpoint = `items/settings?fields=*,translations.*,page_id.*${url}&meta=*&filter[type]=2`;
    if (isLimited) projectsEndpoint += `&limit=${this._limit}`;
    return await HttpService.get(projectsEndpoint);
  };

  //settingss
  getSliders = async (url, isLimited = true) => {
    let projectsEndpoint = `items/settings?fields=*,translations.*,page_id.*&meta=*${url}&filter[type]=1`;
    if (isLimited) projectsEndpoint += `&limit=${this._limit}`;
    return await HttpService.get(projectsEndpoint);
  };

  //settings by id
  getSliderById = async (id) => {
    const projectsEndpoint = `items/settings/${id}?fields=*,translations.*,translations.languages_code.name,translations.languages_code.code`;
    return await HttpService.get(projectsEndpoint);
  };

  addTimelineItem = async (payload) => {
    const endpoint = `items/projects_timeline`;
    return await HttpService.post(endpoint, payload);
  };

  deleteTimelineItem = async (id) => {
    const endpoint = `items/projects_timeline/${id}`;
    return await HttpService.delete(endpoint);
  };

  removeFile = async (id) => {
    const endpoint = `files/${id}`;
    const { data } = await HttpService.delete(endpoint);
    return data;
  };

  deleteProjectImage = async (id, fId) => {
    const endpoint = `items/projects_files/${id}`;
    const { data } = await HttpService.delete(endpoint);
    this.removeFile(fId);
    return data;
  };

  deleteArticleImage = async (id, fId) => {
    const endpoint = `items/article_files/${id}`;
    const { data } = await HttpService.delete(endpoint);
    this.removeFile(fId);
    return data;
  };

  addProjectImages = async (id, files) => {
    for (const key in files) {
      if (Object.hasOwnProperty.call(files, key)) {
        const element = files[key];
        const data = await this.fileUpload(element);
        const endpoint = `items/projects_files`;
        await HttpService.post(endpoint, {
          projects_id: id,
          directus_files_id: data.id,
        });
      }
    }
  };

  addArticleImages = async (id, files) => {
    for (const key in files) {
      if (Object.hasOwnProperty.call(files, key)) {
        const element = files[key];
        const data = await this.fileUpload(element);
        const endpoint = `items/article_files`;
        await HttpService.post(endpoint, {
          article_id: id,
          directus_files_id: data.id,
        });
      }
    }
  };

  //articals tags
  removeArticalTag = async (id) => {
    const endpoint = `items/articals_tags/${id}`;
    return await HttpService.delete(endpoint);
  };

  addArticalTag = async (payload) => {
    const endpoint = `items/articals_tags`;
    return await HttpService.post(endpoint, payload);
  };

  // roles requests
  getRoles = async () => {
    const rolesEndpoint = `roles`;
    return await HttpService.get(rolesEndpoint);
  };

  deleteRole = async (id) => {
    const endpoint = `roles/${id}`;
    return await HttpService.delete(endpoint);
  };

  createRole = async (payload) => {
    const endpoint = `roles`;
    return await HttpService.post(endpoint, payload);
  };

  updateRole = async (payload, id) => {
    const endpoint = `roles/${id}`;
    return await HttpService.patch(endpoint, payload);
  };

  getRole = async (id) => {
    const endpoint = `roles/${id}`;
    return await HttpService.get(endpoint);
  };

  // categories requests
  getCategories = async (filter, isLimited = false) => {
    let categoriesEndpoint = `items/categories?fields=id,translations.name,parent_id.id,parent_id.translations.name,projects.id,children.id&filter[id][_nin]=1,22&meta=*&sort=-date_created`;
    if (isLimited) categoriesEndpoint += `&limit=${this._limit}`;
    if (filter) categoriesEndpoint += filter;
    return await HttpService.get(categoriesEndpoint);
  };

  // categories requests
  getParentCategories = async (filter, isLimited = false) => {
    let categoriesEndpoint = `items/categories?fields=id,translations.name,parent_id.id,parent_id.translations.name,projects.id,children.id&filter[id][_nin]=1,22&filter[parent_id][_null]=true&meta=*&sort=-date_created`;
    if (isLimited) categoriesEndpoint += `&limit=${this._limit}`;
    if (filter) categoriesEndpoint += filter;
    return await HttpService.get(categoriesEndpoint);
  };

  // categories requests
  getChildreensCategories = async (filter, isLimited = false) => {
    let categoriesEndpoint = `items/categories?fields=id,translations.name,parent_id.id,parent_id.translations.name,projects.id,children.id&filter[id][_nin]=1,22&filter[parent_id][_nnull]=true&meta=*&sort=-date_created`;
    if (isLimited) categoriesEndpoint += `&limit=${this._limit}`;
    if (filter) categoriesEndpoint += filter;
    return await HttpService.get(categoriesEndpoint);
  };

  // categories requests
  getSpecialCategories = async () => {
    let categoriesEndpoint = `items/categories?fields=id,translations.name&filter[id][_in]=1,22`;
    return await HttpService.get(categoriesEndpoint);
  };

  deleteCategory = async (id) => {
    const endpoint = `categories/${id}`;
    return await HttpService.delete(endpoint);
  };

  createCategory = async (payload) => {
    const endpoint = `categories`;
    return await HttpService.post(endpoint, payload);
  };

  getCategory = async (id) => {
    const categoriesEndpoint = `categories/${id}`;
    return await HttpService.get(categoriesEndpoint);
  };

  updateCategory = async (payload, id) => {
    const categoriesEndpoint = `categories/${id}`;
    return await HttpService.patch(categoriesEndpoint, payload);
  };

  //for projects
  getItems = async (collection, filter, isLimited = false) => {
    let itemsEndpoint = `items/${collection}?fields=*,translations.name,projects.id&meta=*`;
    if (isLimited) itemsEndpoint += `&limit=${this._limit}`;
    if (filter) itemsEndpoint += filter;
    return await HttpService.get(itemsEndpoint);
  };

  deleteItem = async (collection, id) => {
    const endpoint = `items/${collection}/${id}`;
    return await HttpService.delete(endpoint);
  };
  //for articles
  getArticleItems = async (collection, filter, isLimited = false) => {
    let itemsEndpoint = `items/${collection}?fields=*,translations.name,articles.id&meta=*`;
    if (isLimited) itemsEndpoint += `&limit=${this._limit}`;
    if (filter) itemsEndpoint += filter;
    return await HttpService.get(itemsEndpoint);
  };

  //getTimeLine
  getTimeLine = async (filter, isLimited = false) => {
    let itemsEndpoint = `items/timeline?fields=*,translations.title,translations.desc,projects.id&meta=*`;
    if (isLimited) itemsEndpoint += `&limit=${this._limit}`;
    if (filter) itemsEndpoint += filter;
    return await HttpService.get(itemsEndpoint);
  };

  deleteTimeLine = async (id) => {
    const endpoint = `items/timeline/${id}`;
    return await HttpService.delete(endpoint);
  };

  deleteSettings = async (id) => {
    const endpoint = `items/settings/${id}`;
    return await HttpService.delete(endpoint);
  };

  // tag requests
  getTags = async (filter, isLimited = false) => {
    let tagsEndpoint = `items/tags?fields=*,translations.name,articles.id&meta=*`;
    if (isLimited) tagsEndpoint += `&limit=${this._limit}`;
    if (filter) tagsEndpoint += filter;
    return await HttpService.get(tagsEndpoint);
  };

  // tag requests
  getAuthors = async (filter, isLimited = false) => {
    let authorsEndpoint = `items/authors?fields=*,translations.name,articles.id&meta=*`;
    if (isLimited) authorsEndpoint += `&limit=${this._limit}`;
    if (filter) authorsEndpoint += filter;
    return await HttpService.get(authorsEndpoint);
  };

  deleteTag = async (id) => {
    const endpoint = `items/tags/${id}`;
    return await HttpService.delete(endpoint);
  };

  createTag = async (payload) => {
    const endpoint = `items/tags`;
    return await HttpService.post(endpoint, payload);
  };

  getTag = async (id) => {
    const endpoint = `items/tags/${id}?fields=*,translations`;
    return await HttpService.get(endpoint);
  };

  updateTag = async (payload, id) => {
    const endpoint = `items/tags/${id}`;
    return await HttpService.patch(endpoint, payload);
  };

  // status requests
  // getAllStatus = async (filter) => {
  //   let statusEndpoint = `items/status?fields=*,translations.name&meta=*`;
  //   if (isLimited) statusEndpoint += `&limit=${this._limit}`;
  //   if (filter) statusEndpoint += filter;
  //   return await HttpService.get(statusEndpoint);
  // };

  // deleteStatus = async (id) => {
  //   const endpoint = `items/status/${id}`;
  //   return await HttpService.delete(endpoint);
  // };

  // createStatus = async (payload) => {
  //   const endpoint = `items/status`;
  //   return await HttpService.post(endpoint, payload);
  // };

  // getStatus = async (id) => {
  //   const endpoint = `items/status/${id}`;
  //   return await HttpService.get(endpoint);
  // };

  // updateStatus = async (payload, id) => {
  //   const endpoint = `items/status/${id}`;
  //   return await HttpService.patch(endpoint, payload);
  // };

  // source requests
  // getSources = async (filter) => {
  //   let sourcesEndpoint = `items/sources?fields=*&meta=*&limit=${this._limit}`;
  //   if (filter) sourcesEndpoint += filter;
  //   return await HttpService.get(sourcesEndpoint);
  // };

  // deleteSource = async (id) => {
  //   const endpoint = `items/sources/${id}`;
  //   return await HttpService.delete(endpoint);
  // };

  // createSource = async (payload) => {
  //   const endpoint = `items/sources`;
  //   return await HttpService.post(endpoint, payload);
  // };

  // getSource = async (id) => {
  //   const endpoint = `items/sources/${id}`;
  //   return await HttpService.get(endpoint);
  // };

  // updateSource = async (payload, id) => {
  //   const endpoint = `items/sources/${id}`;
  //   return await HttpService.patch(endpoint, payload);
  // };

  getCalenderData = async (payload) => {
    const endpoint = `custom/getCalenderData`;
    return await HttpService.post(endpoint, payload);
  };

  // users requests
  fileUpload = async (file) => {
    const fileEndpoint = `files`;
    const formData = new FormData();
    formData.append("attachment", file);
    const { data } = await HttpService.post(fileEndpoint, formData, true);
    return data;
  };

  pushReq = async (payload, collection) => {
    if (collection === "projects" || collection === "article") {
      const object = ["ar_pic", "en_pic", "tr_pic"];
      for (const key in object) {
        if (Object.hasOwnProperty.call(object, key)) {
          const element = object[key];
          if (payload[element]) {
            let r = await this.fileUpload(payload[element]);
            payload[`new_${element}`] = r;
          }
        }
      }
      const galleryPhoto = payload.gallery;
      let new_gallery = [];
      for (const key in galleryPhoto) {
        if (Object.hasOwnProperty.call(galleryPhoto, key)) {
          const element = galleryPhoto[key];
          let r = await this.fileUpload(element);
          new_gallery.push(r);
        }
      }
      payload["new_gallery"] = new_gallery;
      delete payload.ar_pic;
      delete payload.en_pic;
      delete payload.tr_pic;
      delete payload.gallery;
    } else if (collection === "settings") {
      const object = ["ar_pic", "en_pic", "tr_pic"];
      for (const key in object) {
        if (Object.hasOwnProperty.call(object, key)) {
          const element = object[key];
          if (payload[element]) {
            let r = await this.fileUpload(payload[element]);
            payload[`new_${element}`] = r;
          }
        }
      }
      delete payload.ar_pic;
      delete payload.en_pic;
      delete payload.tr_pic;
    }

    const endpoint = `items/requests`;
    return await HttpService.post(endpoint, {
      request: payload,
      collection: collection,
    });
  };
}

export default new CrudService();
