/**
=========================================================
* Arakan - v2.1.0
=========================================================

* Product Page: https://www.trakib.com/product/material-dashboard-pro-react
* Copyright 2022 Trakib (https://www.trakib.com)

Coded by www.trakib.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useMemo } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Arakan components
import MDBox from "components/MDBox";

// Settings page components
import Header from "./components/Header";
import MainInfo from "./components/MainInfo";
import LangForm from "./components/LangForm";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import Footer from "examples/Footer";
import crudsService from "services/cruds-service";
import { useNavigate, useParams } from "react-router-dom";
import GalleryForm from "./components/galleryForm";

function EditArticle() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [article, setArticle] = useState({
    id: null,
    author_id: null,
    tags: [],
  });
  const [translations, setTranslations] = useState([]);
  const [gallery, setGallery] = useState([]);
  const [tags, setTags] = useState([]);
  const [authors, setAuthors] = useState([]);

  const prepareNewArr = (arr, objKey) => {
    let newArr = [];
    for (const key in arr) {
      if (Object.hasOwnProperty.call(arr, key)) {
        const element = arr[key];
        newArr.push({
          id: element.id,
          name: element.translations[0][objKey || "name"],
        });
      }
    }
    return newArr;
  };

  const getArticles = async () => {
    const response = await crudsService.getArticleById(id);
    setArticle((prevArticle) => ({
      ...prevArticle,
      id: response.data.id,
      author_id: response.data.author_id,
      tags: response.data.tags,
      date: response.data.date,
      sequence: response.data.sequence,
    }));
    setTranslations(response.data.translations);
    setGallery(response.data.gallery);
  };

  useEffect(() => {
    (async () => {
      if (!id) return;
      getArticles();
      try {
        const tagData = await crudsService.getTags();
        setTags(prepareNewArr(tagData.data));
        const authorsData = await crudsService.getAuthors();
        setAuthors(prepareNewArr(authorsData.data));
      } catch (err) {
        console.error(err);
        return null;
      }
    })();
  }, [id]);

  const langForm = useMemo(
    () =>
      translations.map((t) => (
        <Grid
          container
          spacing={3}
          py={2}
          key={Math.random()}
          id={`${t.languages_code?.code.split("-")[0]}-info`}
        >
          <Grid item xl={4}>
            <Header article={t} data={t.image} />
          </Grid>
          <Grid item xl={8}>
            <LangForm article={t} id={t.id} />
          </Grid>
        </Grid>
      )),
    [translations]
  );

  const getMainInfo = useMemo(() => {
    if (tags.length > 0 && authors.length > 0 && article)
      return <MainInfo article={article} tags={tags} authors={authors} />;
  }, [tags, authors, article]);

  const geGallery = useMemo(() => {
    if (gallery.length > 0 && article)
      return (
        <GalleryForm
          gallery={gallery}
          getArticles={getArticles}
          id={article.id}
        />
      );
  }, [gallery, article]);

  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      <MDBox m={4}>
        <MDBox mb={3}>
          <Grid container spacing={3} justifyContent="center">
            <Grid item xl={12} lg={9}>
              <MDBox mb={3}>
                {langForm}
                <Grid container spacing={3}>
                  <Grid item xl={12}>
                    {getMainInfo}
                  </Grid>
                  <Grid item xl={12}>
                    {geGallery}
                  </Grid>
                </Grid>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default EditArticle;
