/**
=========================================================
* Arakan - v2.1.0
=========================================================

* Product Page: https://www.trakib.com/product/material-dashboard-pro-react
* Copyright 2022 Trakib (https://www.trakib.com)

Coded by www.trakib.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useMemo } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Arakan components
import MDBox from "components/MDBox";

// Settings page components
import MainInfo from "./components/MainInfo";
import LangForm from "./components/LangForm";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import crudsService from "services/cruds-service";
import {useParams } from "react-router-dom";

function EditProject() {
  const { id } = useParams();
  const [project, setProject] = useState({
    id: null,
    category: null,
    cost: "",
  });
  const [translations, setTranslations] = useState([]);
  const [categories, setCategories] = useState([]);

  const prepareNewArr = (arr, objKey) => {
    let newArr = [];
    for (const key in arr) {
      if (Object.hasOwnProperty.call(arr, key)) {
        const element = arr[key];
        newArr.push({
          id: element.id,
          name: element.translations[0][objKey || "name"],
        });
      }
    }
    console.log(newArr);
    return newArr;
  };

  const getProjects = async () => {
    const response = await crudsService.getProjectById(id);
    setProject((prevProject) => ({
      ...prevProject,
      id: response.data.id,
      category: response.data.category_id,
      cost: response.data.cost,
      sequence: response.data.sequence,
    }));
    setTranslations(response.data.translations);
  };

  useEffect(() => {
    (async () => {
      if (!id) return;
      getProjects();
      try {
        const categoryData = await crudsService.getSpecialCategories();
        setCategories(prepareNewArr(categoryData.data));
      } catch (err) {
        console.error(err);
        return null;
      }
    })();
  }, [id]);

  const langForm = useMemo(
    () =>
      translations.map((t) => (
        <Grid
          container
          spacing={3}
          py={2}
          key={Math.random()}
          id={`${t.languages_code?.code.split("-")[0]}-info`}
        >
          <Grid item xl={12}>
            <LangForm project={t} id={t.id} />
          </Grid>
        </Grid>
      )),
    [translations]
  );

  const getMainInfo = useMemo(() => {
    if (
      categories.length > 0 &&
      project
    )
      return (
        <MainInfo
          project={project}
          categories={categories}
        />
      );
  }, [categories ,project]);


  return (
    <DashboardLayout>
      <MDBox m={4}>
        <MDBox mb={3}>
          <Grid container spacing={3} justifyContent="center">
            <Grid item xl={12} lg={9}>
              <MDBox mb={3}>
                {langForm}
                <Grid container spacing={3}>
                  <Grid item xl={12}>
                    {getMainInfo}
                  </Grid>
                </Grid>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default EditProject;
