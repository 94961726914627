/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// NewUser page components
import FormField from "../FormField";
import { useState } from "react";
import { InputLabel } from "@mui/material";
import MDInput from "components/MDInput";
import MDAvatar from "components/MDAvatar";

function ProjectImage({ formData, lang }) {
  const { formField, values, errors, touched, setFieldValue, setFieldTouched } =
    formData;
  const { ar_pic, en_pic, tr_pic } = formField;

  const [arPicUrl, setArPicUrl] = useState(null);
  const [enPicUrl, setEnPicUrl] = useState(null);
  const [trPicUrl, setTrPicUrl] = useState(null);

  const changeArPicHandler = (e) => {
    setFieldValue(ar_pic.name, e.target.files[0]);
    setFieldTouched(ar_pic.name, e.target.files[0]);
    setArPicUrl(URL.createObjectURL(e.target.files[0]));
  };

  const changeEnPicHandler = (e) => {
    setFieldValue(en_pic.name, e.target.files[0]);
    setFieldTouched(en_pic.name, e.target.files[0]);
    setEnPicUrl(URL.createObjectURL(e.target.files[0]));
  };

  const changeTrPicHandler = (e) => {
    setFieldValue(tr_pic.name, e.target.files[0]);
    setFieldTouched(tr_pic.name, e.target.files[0]);
    setTrPicUrl(URL.createObjectURL(e.target.files[0]));
  };

  return (
    <MDBox>
      <MDTypography variant="h5" fontWeight="bold">
        {lang?.name}
      </MDTypography>
      <MDBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <MDBox
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <MDBox mt={2} display="flex" flexDirection="column">
                {arPicUrl && (
                  <MDBox ml={4} mt={2}>
                    {/* <MDAvatar
                    src={arPicUrl}
                    alt="profile-image"
                    size="xxl"
                    shadow="sm"
                  /> */}
                    <MDBox
                      component="img"
                      src={arPicUrl}
                      alt="Product Image"
                      borderRadius="lg"
                      shadow="sm"
                      width="100%"
                      height="10%"
                      mb={2}
                    />
                  </MDBox>
                )}
                <InputLabel id="demo-simple-select-label">
                  {ar_pic?.label}
                </InputLabel>
                <MDInput
                  type="file"
                  name="attachment"
                  onChange={changeArPicHandler}
                  id="file-input"
                  accept="image/*"
                  sx={{ mt: "16px" }}
                  label={ar_pic?.label}
                  name="ar_pic"
                  error={errors[`ar_pic`] && touched[`ar_pic`]}
                  success={values[`ar_pic`]?.length > 0 && !errors[`ar_pic`]}
                ></MDInput>
              </MDBox>

              {/* {arPicUrl && (
                <MDBox ml={4} mt={2}>
                  {/* <MDAvatar
                    src={arPicUrl}
                    alt="profile-image"
                    size="xxl"
                    shadow="sm"
                  /> 
                  <MDBox
                    component="img"
                    src={arPicUrl}
                    alt="Product Image"
                    borderRadius="lg"
                    shadow="sm"
                    width="100%"
                    height="10%"
                    // position="relative"
                    // zIndex={10}
                    mb={2}
                  />
                </MDBox>
              )} */}
            </MDBox>
          </Grid>
          <Grid item xs={12}>
            <MDBox
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <MDBox mt={2} display="flex" flexDirection="column">
                {enPicUrl && (
                  <MDBox ml={4} mt={2}>
                    <MDBox
                      component="img"
                      src={enPicUrl}
                      alt="Product Image"
                      borderRadius="lg"
                      shadow="sm"
                      width="100%"
                      height="10%"
                      mb={2}
                    />
                  </MDBox>
                )}
                <InputLabel id="demo-simple-select-label">
                  {en_pic?.label}
                </InputLabel>
                <MDInput
                  type="file"
                  name="attachment"
                  onChange={changeEnPicHandler}
                  id="file-input"
                  accept="image/*"
                  sx={{ mt: "16px" }}
                ></MDInput>
              </MDBox>

              {/* {enPicUrl && (
                <MDBox ml={4} mt={2}>
                  <MDAvatar
                    src={enPicUrl}
                    alt="profile-image"
                    size="xxl"
                    shadow="sm"
                  />
                </MDBox>
              )} */}
            </MDBox>
          </Grid>
          <Grid item xs={12}>
            <MDBox
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <MDBox mt={2} display="flex" flexDirection="column">
                {trPicUrl && (
                  <MDBox ml={4} mt={2}>
                    <MDBox
                      component="img"
                      src={trPicUrl}
                      alt="Product Image"
                      borderRadius="lg"
                      shadow="sm"
                      width="100%"
                      height="10%"
                      mb={2}
                    />
                  </MDBox>
                )}
                <InputLabel id="demo-simple-select-label">
                  {tr_pic?.label}
                </InputLabel>
                <MDInput
                  type="file"
                  name="attachment"
                  onChange={changeTrPicHandler}
                  id="file-input"
                  accept="image/*"
                  sx={{ mt: "16px" }}
                ></MDInput>
              </MDBox>

              {/* {trPicUrl && (
                <MDBox ml={4} mt={2}>
                  <MDAvatar
                    src={trPicUrl}
                    alt="profile-image"
                    size="xxl"
                    shadow="sm"
                  />
                </MDBox>
              )} */}
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

// typechecking props for ProjectImage
ProjectImage.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default ProjectImage;
