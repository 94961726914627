/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const form = {
  formId: "new-tag-form",
  formField: {
    ar_title: {
      name: "ar_title",
      label: "الاسم",
      type: "text",
      errorMsg: "Arabic title is required.",
    },
    en_title: {
      name: "en_title",
      label: "الاسم",
      type: "text",
      errorMsg: "english title is required.",
    },
    tr_title: {
      name: "tr_title",
      label: "الاسم",
      type: "text",
      errorMsg: "turkish title is required.",
    },
    ar_desc: {
      name: "ar_desc",
      label: "الوصف",
      type: "text",
      errorMsg: "Arabic desc is required.",
    },
    en_desc: {
      name: "en_desc",
      label: "الوصف",
      type: "text",
      errorMsg: "english desc is required.",
    },
    tr_desc: {
      name: "tr_desc",
      label: "الوصف",
      type: "text",
      errorMsg: "turkish desc is required.",
    },
  },
};

export default form;
