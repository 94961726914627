/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// NewUser page components
import FormField from "../FormField";
import { useState } from "react";
import { InputLabel } from "@mui/material";
import MDInput from "components/MDInput";
import MDAvatar from "components/MDAvatar";

function LangForm({ formData, lang }) {
  const { formField, values, errors, touched, setFieldValue, setFieldTouched } =
    formData;
  const field = lang.code?.split("-")[0];

  return (
    <MDBox>
      <MDTypography variant="h5" fontWeight="bold">
        {lang?.name}
      </MDTypography>
      <MDBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormField
              type={formField[`${field}_name`]?.type}
              label={formField[`${field}_name`]?.label}
              name={formField[`${field}_name`]?.name}
              value={values[`${field}_name`]}
              placeholder={formField[`${field}_name`]?.placeholder}
              error={errors[`${field}_name`] && touched[`${field}_name`]}
              success={
                values[`${field}_name`]?.length > 0 && !errors[`${field}_name`]
              }
            />
          </Grid>
          <Grid item xs={12}>
            <FormField
              multiline
              rows={5}
              type={formField[`${field}_desc`]?.type}
              label={formField[`${field}_desc`]?.label}
              name={formField[`${field}_desc`]?.name}
              value={values[`${field}_desc`]}
              placeholder={formField[`${field}_desc`]?.placeholder}
              error={errors[`${field}_desc`] && touched[`${field}_desc`]}
              success={
                values[`${field}_desc`]?.length > 0 && !errors[`${field}_desc`]
              }
            />
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

// typechecking props for LangForm
LangForm.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default LangForm;
