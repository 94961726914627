/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// NewUser page components
import { Autocomplete } from "@mui/material";
// import { useState } from "react";
import MDInput from "components/MDInput";

function Other({ formData, lang, pages = [] }) {
  const { formField, values, errors, touched, setFieldValue, setFieldTouched } =
    formData;
  const { page_id } = formField;
  const { page_id: page_idV } = values;

  const autoChangeHandler = (name, newValue) => {
    setFieldValue(name, newValue);
    setFieldTouched(name, newValue);
  };

  return (
    <MDBox>
      <MDTypography variant="h5" fontWeight="bold">
        {lang?.name}
      </MDTypography>
      <MDBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <Autocomplete
              options={pages}
              name={page_id.name}
              renderOption={(props, option) => (
                <li {...props} key={option.id}>
                  {option.data ? option.data.name : option.name}
                </li>
              )}
              getOptionLabel={(option) => {
                if (option.data) {
                  if (option.data.name) return option.data.name;
                } else {
                  if (option) {
                    if (option.name) return option.name;
                  }
                }
                return "";
              }}
              isOptionEqualToValue={(option, item) =>
                option === item || option.id === item.id
              }
              variant="outlined"
              renderInput={(params) => (
                <MDInput
                  {...params}
                  //variant="standard"
                  label={page_id.label}
                  error={errors.page_id && touched.page_id}
                  success={page_idV?.length > 0 && !errors.page_id}
                />
              )}
              onChange={(e, v) => autoChangeHandler(page_id.name, v)}
              value={page_idV}
            />
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

// typechecking props for Other
Other.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default Other;
