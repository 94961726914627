/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import * as Yup from "yup";
import checkout from "./form";

const {
  formField: { ar, en, tr },
} = checkout;

const validations = [
  Yup.object().shape({
    [ar.name]: Yup.string().required(ar.errorMsg),
  }),
  Yup.object().shape({
    [en.name]: Yup.string().required(en.errorMsg),
  }),
  Yup.object().shape({
    [tr.name]: Yup.string().required(tr.errorMsg),
  }),
];

export default validations;
