/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import checkout from "./form";

const {
  formField: {
    ar_name,
    ar_desc,
    en_name,
    en_desc,
    tr_name,
    tr_desc,
    page_id,
    ar_pic,
    en_pic,
    tr_pic,
  },
} = checkout;

const initialValues = {
  [ar_name.name]: "",
  [en_name.name]: "",
  [tr_name.name]: "",
  [ar_desc.name]: "",
  [en_desc.name]: "",
  [tr_desc.name]: "",
  [page_id.name]: null,
  [ar_pic.name]: null,
  [en_pic.name]: null,
  [tr_pic.name]: null,
};

export default initialValues;
