// import { initReactI18next } from "react-i18next";
// import LanguageDetector from "i18next-browser-languagedetector";
import i18n from "i18next";
import translationsAR from "../locales/ar/translation.json";

const resources = { 
  ar: {
    translation: translationsAR,
  },
};

i18n
  .init({
    resources,
    fallbackLng: "ar", // default language

  });

export default i18n;
