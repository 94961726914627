/**
=========================================================
* Arakan - v2.1.0
=========================================================

* Product Page: https://www.trakib.com/product/material-dashboard-pro-react
* Copyright 2022 Trakib (https://www.trakib.com)

Coded by www.trakib.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// @material-ui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Arakan components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDAlert from "components/MDAlert";

// Settings page components
import FormField from "layouts/pages/account/components/FormField";

import crudsService from "services/cruds-service";
import { Autocomplete, Switch } from "@mui/material";
import MDInput from "components/MDInput";
import i18n from "i18n";

function MainInfo({ tags, authors, article }) {
  const [author, setAuthor] = useState(null);
  const [tag, setTag] = useState([]);
  const { t } = i18n;

  const [info, setInfo] = useState({
    author_id: null,
    tags_id: [],
    sequence: 0,
  });
  const [notification, setNotification] = useState({
    value: false,
    color: "info",
    message: "",
  });

  useEffect(() => {
    setInfo({
      date: article.date,
      sequence: article.sequence,
    });
  }, [article]);

  useEffect(() => {
    if (article.author_id !== "" && authors.length > 0) {
      const author_id = authors.find(
        (author_id) => author_id.id === article.author_id
      );
      setAuthor(author_id);
    }
  }, [authors, article]);

  const prepareNewArr = (arr, objKey) => {
    let newArr = [];
    for (const key in arr) {
      if (Object.hasOwnProperty.call(arr, key)) {
        const element = arr[key];
        newArr.push({
          id: element.tags_id.id,
          name: element.tags_id.translations[0][objKey || "name"],
        });
      }
    }
    return newArr;
  };

  useEffect(() => {
    if (article.tags.length > 0 && tags.length > 0) {
      const tagsItems = [];
      prepareNewArr(article.tags).forEach((tagItem) => {
        tagsItems.push(tagItem);
      });
      setTag(tagsItems);
    }
  }, [tags, article]);

  useEffect(() => {
    if (notification.value === true) {
      setTimeout(() => {
        setNotification({ value: false, color: "info", message: "" });
      }, 5000);
    }
  }, [notification]);

  const [errors, setErrors] = useState({
    author_id: false,
    tags_id: false,
    date: false,
    sequence: false,
  });

  const changeHandler = (e) => {
    setInfo({
      ...info,
      [e.target.name]: e.target.value,
    });
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    if (!author) {
      setNotification({
        value: true,
        message: "لم تختار المؤلف",
        color: "error",
      });
      return;
    }

    if (!info.date) {
      setNotification({
        value: true,
        message: "لم تدخل التاريخ",
        color: "error",
      });
      return;
    }

    const articleData = {
      author_id: author.id,
      date: info.date,
      sequence: info.sequence,
    };

    // call api for update
    await crudsService.updateArticleInfo(articleData, article.id);

    // reset errors
    setErrors({
      author_id: false,
      tags_id: false,
      date: false,
      sequence: false,
    });

    setNotification({
      value: true,
      color: "info",
      message: "Your profile has been updated",
    });
  };

  return (
    <>
      <Card id="basic-info" sx={{ overflow: "visible" }}>
        <MDBox p={3}>
          <MDTypography variant="h5">بيانات المشروع</MDTypography>
        </MDBox>
        <MDBox
          component="form"
          pb={3}
          px={3}
          method="POST"
          onSubmit={submitHandler}
        >
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Autocomplete
                options={authors}
                name="author_id"
                renderOption={(props, option) => (
                  <li {...props} key={option.id}>
                    {option.data ? option.data.name : option.name}
                  </li>
                )}
                getOptionLabel={(option) => {
                  if (option.data) {
                    if (option.data.name) return option.data.name;
                  } else {
                    if (option) {
                      if (option.name) return option.name;
                    }
                  }
                  return "";
                }}
                isOptionEqualToValue={(option, item) =>
                  option === item || option?.id === item?.id
                }
                variant="outlined"
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    //variant="standard"
                    label="المؤلف"
                    error={errors.author_id}
                  />
                )}
                onChange={(e, v) => setAuthor(v)}
                value={author}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                multiple
                options={tags}
                name="tags_id"
                onChange={async (event, newValue, reason, detail) => {
                  if (reason === "removeOption") {
                    let removedItem = article.tags.find(
                      (orgItem) => orgItem.tags_id.id === detail.option.id
                    );
                    await crudsService.removeArticleTags(removedItem.id);
                  } else if (reason === "selectOption")
                    await crudsService.addArticleTags({
                      tags_id: detail.option.id,
                      article_id: article.id,
                    });
                  setTag(newValue);
                }}
                renderOption={(props, option) => (
                  <li {...props} key={option.id}>
                    {option.data ? option.data.name : option.name}
                  </li>
                )}
                getOptionLabel={(option) => {
                  if (option.data) {
                    if (option.data.name) return option.data.name;
                  } else {
                    if (option) {
                      if (option.name) return option.name;
                    }
                  }
                  return "";
                }}
                isOptionEqualToValue={(option, item) =>
                  option === item || option.id === item.id
                }
                renderInput={(params) => (
                  <MDInput
                    variant="outlined"
                    {...params}
                    //variant="standard"
                    label="الوسوم"
                    error={errors.tags_id}
                  />
                )}
                // onChange={(e, v) => setTag(v)}
                value={tag}
              />
            </Grid>
            <Grid item xs={6}>
              <FormField
                type="date"
                onChange={changeHandler}
                label={t("date")}
                name="date"
                value={info.date}
                placeholder="date"
                error={errors.date}
              />
            </Grid>
            <Grid item xs={6}>
              <FormField
                onChange={changeHandler}
                label="الترتيب"
                name="sequence"
                value={info.sequence}
                placeholder={"sequence"}
                error={errors.sequence}
              />
            </Grid>
          </Grid>
          <MDBox ml="auto" mt={2} display="flex" justifyContent="flex-end">
            <MDButton
              variant="gradient"
              color="dark"
              size="small"
              type="submit"
            >
              {t("update")}
            </MDButton>
          </MDBox>
        </MDBox>
      </Card>
      {notification.value === true && (
        <MDAlert color={notification.color} mt="20px">
          <MDTypography variant="body2" color="white">
            {notification.message}
          </MDTypography>
        </MDAlert>
      )}
    </>
  );
}

export default MainInfo;
