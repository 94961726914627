/**
=========================================================
* Arakan - v2.1.0
=========================================================

* Product Page: https://www.trakib.com/product/material-dashboard-pro-react
* Copyright 2022 Trakib (https://www.trakib.com)

Coded by www.trakib.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// @material-ui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Arakan components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDAlert from "components/MDAlert";

// Settings page components
import FormField from "layouts/pages/account/components/FormField";

import crudsService from "services/cruds-service";

function LangForm({ slider, id }) {
  const [info, setInfo] = useState({
    title: "",
    desc: "",
  });
  const [notification, setNotification] = useState({
    value: false,
    color: "info",
    message: "",
  });

  useEffect(() => {
    setInfo({
      title: slider.title,
      desc: slider.desc,
    });
  }, [slider]);

  useEffect(() => {
    if (notification.value === true) {
      setTimeout(() => {
        setNotification({ value: false, color: "info", message: "" });
      }, 5000);
    }
  }, [notification]);

  const [errors, setErrors] = useState({
    title: false,
    desc: false,
  });

  const changeHandler = (e) => {
    setInfo({
      ...info,
      [e.target.name]: e.target.value,
    });
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    if (info.title.trim().length === 0) {
      setNotification({
        value: true,
        message: "لم تدخل الاسم",
        color: "error",
      });
      return;
    }

    // set new slider data for call
    const sliderData = {
      title: info.title,
      desc: info.desc,
    };

    // call api for update
    await crudsService.updateSlider(sliderData, id);

    // reset errors
    setErrors({
      firstNameError: false,
      lastNameError: false,
      emailError: false,
    });

    setNotification({
      value: true,
      color: "info",
      message: "تم التعديل بنجاح",
    });
  };

  return (
    <>
      <Card sx={{ overflow: "visible" }}>
        <MDBox p={3}>
          <MDTypography variant="h5">
            {slider.languages_code?.name}
          </MDTypography>
        </MDBox>
        <MDBox
          component="form"
          pb={3}
          px={3}
          method="POST"
          onSubmit={submitHandler}
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormField
                onChange={changeHandler}
                label="الاسم"
                name="title"
                value={info[`title`]}
                error={errors.titleError}
              />
            </Grid>
            <Grid item xs={12}>
              <FormField
                multiline
                rows={5}
                onChange={changeHandler}
                label="التفاصيل"
                name="desc"
                value={info[`desc`]}
                error={errors.nameError}
              />
            </Grid>
          </Grid>
          <MDBox ml="auto" mt={2} display="flex" justifyContent="flex-end">
            <MDButton
              variant="gradient"
              color="dark"
              size="small"
              type="submit"
            >
              تعديل
            </MDButton>
          </MDBox>
        </MDBox>
      </Card>
      {notification.value === true && (
        <MDAlert color={notification.color} mt="20px">
          <MDTypography variant="body2" color="white">
            {notification.message}
          </MDTypography>
        </MDAlert>
      )}
    </>
  );
}

export default LangForm;
