/**
=========================================================
* Arakan - v2.1.0
=========================================================

* Product Page: https://www.trakib.com/product/material-dashboard-pro-react
* Copyright 2022 Trakib (https://www.trakib.com)

Coded by www.trakib.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// import EditTag from "cruds/tag-management/edit-tag";
import AddTag from "cruds/article-management/settings/tag/add-tag";
// import EditTag from "cruds/article-management/settings/update/add-tag";
// import EditStatus from "cruds/status-management/edit-status";
// import NewStatus from "cruds/status-management/add-status";
// import EditSource from "cruds/source-management/edit-source";
// import NewSource from "cruds/source-management/add-source";
// import EditUser from "cruds/user-management/edit-user";
// import NewUser from "cruds/user-management/add-user";
import EditCustomer from "cruds/user-management/custumers/edit-user";
import NewCustomer from "cruds/user-management/custumers/add-user";
import EditEmployee from "cruds/user-management/employees/edit-user";
import NewEmployee from "cruds/user-management/employees/add-user";
import AddItems from "cruds/project-management/settings/item/add-item";
import AddTimeLine from "cruds/project-management/settings/timeline/add-timeline";
import AddCategories from "cruds/project-management/settings/category/add-category";
import AddSpecialProjects from "cruds/project-management/special-projects/add-project";
import AddProjects from "cruds/project-management/projects/add-project";
import EditProjects from "cruds/project-management/projects/edit-project";
import EditSpecialProjects from "cruds/project-management/special-projects/edit-project";
import AddArticles from "cruds/article-management/articles/add-article";
import EditArticles from "cruds/article-management/articles/edit-article";
import AddSliders from "cruds/website-management/sliders/add-slider";
import EditSliders from "cruds/website-management/sliders/edit-slider";
import AddContent from "cruds/website-management/settings/add-setting";
import EditContent from "cruds/website-management/settings/edit-setting";

// import EditItems from "cruds/project-management/settings/item/edit-item";
/** 
  All of the routes for the Arakan are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Arakan layouts

const crudRoutes = [
  {
    key: "add-tag",
    route: "/articleManagement/tags/add/tag",
    component: <AddTag />,
    type: "tags",
  },
  {
    key: "add-status",
    route: "/projectManagement/project_status/add/project_status",
    component: <AddItems />,
    type: "status",
  },
  {
    key: "edit-project",
    route: "/projectManagement/:id",
    component: <EditProjects />,
    type: "projects",
  },
  {
    key: "edit-special-project",
    route: "/projectManagement/special-project/:id",
    component: <EditSpecialProjects />,
    type: "projects",
  },

  {
    key: "add-category",
    route: "/projectManagement/categories/add/categories",
    component: <AddCategories />,
    type: "categories",
  },
  // {
  //   key: "edit-category",
  //   route: "/projectManagement/categories/update/categories/:id",
  //   component: <EditCategories />,
  //   type: "category",
  // },
  {
    key: "add-project",
    route: "/projectManagement/projects/add/project",
    component: <AddProjects />,
    type: "projects",
  },
  {
    key: "add-special-project",
    route: "/projectsManagement/add/special-project",
    component: <AddSpecialProjects />,
    type: "projects",
  },
  {
    key: "edit-article",
    route: "/articleManagement/:id",
    component: <EditArticles />,
    type: "articles",
  },
  {
    key: "add-article",
    route: "/articleManagement/articles/add/article",
    component: <AddArticles />,
    type: "articles",
  },
  {
    key: "edit-slider",
    route: "websiteManagement/slider/:id",
    component: <EditSliders />,
    type: "settings",
  },
  {
    key: "add-slider",
    route: "/websiteManagement/add/slider",
    component: <AddSliders />,
    type: "settings",
  },
  {
    key: "edit-content",
    route: "websiteManagement/content/:id",
    component: <EditContent />,
    type: "contents",
  },
  {
    key: "add-content",
    route: "/websiteManagement/add/content",
    component: <AddContent />,
    type: "contents",
  },
  // {
  //   key: "edit-category",
  //   route: "/projectManagement/categories/update/categories/:id",
  //   component: <EditCategories />,
  //   type: "category",
  // },
  {
    key: "add-countries",
    route: "/projectManagement/countries/add/countries",
    component: <AddItems />,
    type: "countries",
  },
  {
    key: "add-authors",
    route: "/articleManagement/authors/add/authors",
    component: <AddItems />,
    type: "authors",
  },
  // {
  //   key: "edit-status",
  //   route: "/projectManagement/countries/update-countries/:id",
  //   component: <EditItems />,
  //   type: "status",
  // },
  {
    key: "add-target_segment",
    route: "/projectManagement/target_segment/add/target_segment",
    component: <AddItems />,
    type: "target_segment",
  },
  // {
  //   key: "edit-target_segment",
  //   route: "/projectManagement/target_segment/update-target_segment/:id",
  //   component: <EditItems />,
  //   type: "target_segment",
  // },
  {
    key: "add-timeline",
    route: "/projectManagement/timeline/add/timeline",
    component: <AddTimeLine />,
    type: "timeline",
  },
  // {
  //   key: "edit-timeline",
  //   route: "/projectManagement/timeline/update-timeline/:id",
  //   component: <EditItems />,
  //   type: "timeline",
  // },
  // {
  //   key: "add-source",
  //   route: "/sourceManagement/addSource",
  //   component: <NewSource />,
  //   type: "sources",
  // },
  // {
  //   key: "edit-source",
  //   route: "/sourceManagement/updateSource/:id",
  //   component: <EditSource />,
  //   type: "sources",
  // },
  // {
  //   key: "add-user",
  //   route: "/userManagement/addUser/",
  //   component: <NewUser />,
  //   type: "users",
  // },
  // {
  //   key: "edit-user",
  //   route: "/userManagement/updateUser/:id",
  //   component: <EditUser />,
  //   type: "users",
  // },
  {
    key: "add-customer",
    route: "/userManagement/customers/addCustomer/",
    component: <NewCustomer />,
    type: "users",
  },
  {
    key: "edit-customer",
    route: "/userManagement/customers/updateCustomer/:id",
    component: <EditCustomer />,
    type: "users",
  },
  {
    key: "add-employee",
    route: "/userManagement/employees/addEmployee/",
    component: <NewEmployee />,
    type: "users",
  },
  {
    key: "edit-employee",
    route: "/userManagement/employees/updateEmployee/:id",
    component: <EditEmployee />,
    type: "users",
  },
];

export default crudRoutes;
