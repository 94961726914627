/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com
 
 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// NewUser page components
import { useEffect, useState } from "react";
// import { setDate } from "date-fns";
import { Card } from "@mui/material";
import crudsService from "services/cruds-service";

// NewUser page components
import MDDropzone from "components/MDDropzone";
import { useRef } from "react";
import Grid from "@mui/material/Grid";

function galleryForm({ id, gallery, getArticles }) {
  // const { formField, values, errors, setFieldValue, setFieldTouched } =
  //   formData;
  // const { time_line_items } = formField;
  // const { time_line_items: time_line_itemsV } = values;
  const baseUrl = process.env.REACT_APP_API_URL;
  const dropzoneRef = useRef();

  const [notification, setNotification] = useState({
    value: false,
    color: "info",
    message: "",
  });

  useEffect(() => {
    if (notification.value === true) {
      setTimeout(() => {
        setNotification({ value: false, color: "info", message: "" });
      }, 5000);
    }
  }, [notification]);

  const deleteItem = async (id, fid) => {
    await crudsService.deleteArticleImage(id, fid);
    getArticles();
  };

  const getItems = () => {
    console.log(gallery);
    return (
      <Grid container spacing={3}>
        {gallery.map((t, i) => (
          <Grid item xs={3}>
            <MDBox
              component="img"
              src={`${baseUrl}assets/${t.directus_files_id.id}`}
              alt="Product Image"
              borderRadius="lg"
              shadow="sm"
              width="100%"
              // height="100%"
              mb={2}
              onClick={() => {
                deleteItem(t.id, t.directus_files_id.id);
              }}
            />
          </Grid>
        ))}
      </Grid>
    );
  };

  const getAcceptedFiles = async (files) => {
    await crudsService.addArticleImages(id, files);
    getArticles();
  };

  return (
    <>
      <Card id="gallery" sx={{ overflow: "visible" }}>
        <MDBox p={3}>
          <MDTypography variant="h5">المعرض</MDTypography>
          <MDTypography variant="button">
            يتم مسح الصورة بالضغط عليها
          </MDTypography>
        </MDBox>
        <MDBox pb={3} px={3}>
          {getItems()}
        </MDBox>
        <MDBox component="form" pb={3} px={3}>
          <MDDropzone
            getAcceptedFiles={getAcceptedFiles}
            ref={dropzoneRef}
            label="gallery"
            name="gallery"
            value="gallery"
          />
        </MDBox>
      </Card>
      {notification.value === true && (
        <MDAlert color={notification.color} mt="20px">
          <MDTypography variant="body2" color="white">
            {notification.message}
          </MDTypography>
        </MDAlert>
      )}
    </>
  );
}

// typechecking props for galleryForm
galleryForm.propTypes = {
  // formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default galleryForm;
