/**
=========================================================
* Arakan - v2.1.0
=========================================================

* Product Page: https://www.trakib.com/product/material-dashboard-pro-react
* Copyright 2022 Trakib (https://www.trakib.com)

Coded by www.trakib.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Arakan components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAlert from "components/MDAlert";

// Arakan examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import { Tooltip, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
// import EditIcon from "@mui/icons-material/Edit";
// import Icon from "@mui/material/Icon";
// import Grid from "@mui/material/Grid";
// import booking3 from "assets/images/products/product-3-min.jpg";

// import AuthService from "services/auth-service";
import CrudService from "services/cruds-service";
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { AbilityContext } from "Can";
import { useAbility } from "@casl/react";
// import BookingCard from "examples/Cards/BookingCard";
import i18n from "i18n";
import MDAvatar from "components/MDAvatar";
import MDButton from "components/MDButton";
import StatusOnIcon from "@mui/icons-material/Wifi";
import StatusOffIcon from "@mui/icons-material/WifiOff";
// import FilterProject from "./filter-slider";

function Items() {
  let { state } = useLocation();
  const { t } = i18n;

  const ability = useAbility(AbilityContext);
  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);
  // const [statusStatistics, setStatusStatistics] = useState([]);
  const [meta, setMeta] = useState(null);
  const [page, setPage] = useState(1);
  const [limitChange, setLimitChange] = useState(false);
  const [sortVal, setSortVal] = useState(null);
  const [sortUrl, setSortUrl] = useState("&sort=-date_created");
  // const [isAdmin, setIsAdmin] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [filterUrl, setFilterUrl] = useState(null);
  const [defaultFilterUrl, setDefaultFilterUrl] = useState("");
  const [notification, setNotification] = useState({
    value: false,
    text: "",
  });

  const navigate = useNavigate();
  const baseUrl = process.env.REACT_APP_API_URL;

  const getSliders = async (filUrl) => {
    const URL = `${filUrl ? `${filUrl}&page=${page}` : `&page=${page}`}`;
    const response = await CrudService.getSliders(URL);
    setData(response.data);
    setMeta(response.meta);
  };

  // useEffect(() => {
  //   getSliders(`${filterUrl || ""}${sortUrl}`);
  // }, [page]);

  // useEffect(() => {
  //   getSliders(`${filterUrl || ""}${sortUrl}`);
  //   setLimitChange(false);
  // }, [limitChange]);

  // useEffect(() => {
  //   if (page === 1) getSliders(`${filterUrl || ""}${sortUrl}`);
  //   else if (page !== 1) setPage(1);
  // }, [filterUrl, sortUrl]);

  useEffect(() => {
    console.log("page", page);
    getSliders(`${filterUrl || ""}${sortUrl}`);
  }, [page]);

  useEffect(() => {
    if (data.length > 0) {
      console.log("limitChange", limitChange);
      getSliders(`${filterUrl || ""}${sortUrl}`);
      setLimitChange(false);
    }
  }, [limitChange]);

  useEffect(() => {
    if (data.length > 0) {
      console.log("filterUrl, sortUrl", filterUrl, sortUrl);
      setPage(1);
    }
  }, [filterUrl, sortUrl]);

  useEffect(() => {
    setTableData(getRows(data));
  }, [data]);

  useEffect(() => {
    if (!state) return;
    setNotification({
      value: state.value,
      text: state.text,
    });
  }, [state]);

  useEffect(() => {
    if (notification.value === true) {
      setTimeout(() => {
        setNotification({
          value: false,
          text: "",
        });
      }, 5000);
    }
  }, [notification]);

  const clickShowSliderDetailsHandler = (id) => {
    // (ability.can("edit", "settings") || isAdmin) &&
    navigate(`/websiteManagement/slider/${id}`);
  };

  const clickDeleteHandler = async (e, id) => {
    try {
      if (!confirm(t("confirmDelete"))) {
        e.nativeEvent.stopImmediatePropagation();
      } else {
        await CrudService.deleteSettings(id);
        // the delete does not send a response
        // so I need to get again the appointments to set it and this way the table gets updated -> it goes to the useEffect with data dependecy
        // const response = await CrudService.getContents(filterUrl);
        // setData(response.data);
        getSliders(`${filterUrl || ""}${sortUrl}`);
        setNotification({
          value: true,
          text: t("deleteSuccessfully"),
        });
      }
    } catch (err) {
      // it sends error is the page is associated with an item
      console.error(err);
      if (err.hasOwnProperty("errors")) {
        setNotification({
          value: true,
          text: err.errors[0].title,
        });
      }
      return null;
    }
  };

  const getRows = (info) => {
    let updatedInfo = info.map((row) => {
      return {
        id: row.id,
        name: row.translations[0].title,
        status: row.status,
        desc: row.translations[0].desc,
        page: row.page_id.arname,
        pic: row.translations[0].image,
      };
    });
    return updatedInfo;
  };

  const updateStatusHandler = async (e, data) => {
    let obj;
    if (data.status === "published") obj = { status: "draft" };
    else obj = { status: "published" };
    await CrudService.updateSliderInfo(obj, data.id);

    getSliders(`${filterUrl || ""}${sortUrl}`);
    setNotification({
      value: true,
      text: t("statusChanged"),
    });
  };

  const dataTableData = {
    columns: [
      // {
      //   Header: "الصورة الرئيسية",
      //   accessor: "pic",
      //   Cell: (info) => {
      //     return (
      //       <MDAvatar
      //         src={`${baseUrl}assets/${info.cell.row.values.pic}`}
      //         alt="Avatar"
      //         variant="square"
      //       />
      //     );
      //   },
      // },
      { Header: "الصفحة", accessor: "page", width: "10%" },
      { Header: "العنوان", accessor: "name", width: "10%" },
      // {
      //   Header: "الحالة",
      //   accessor: "status",
      //   Cell: (info) => {
      //     return (
      //       <MDBox
      //         sx={{
      //           backgroundColor:
      //             info.cell.row.values.status === "published"
      //               ? "green"
      //               : "gray",
      //           borderRadius: 16,
      //           cursor: "pointer",
      //         }}
      //         px={2}
      //         py={1}
      //         onClick={() =>
      //           clickShowSliderDetailsHandler(info.cell.row.original.id)
      //         }
      //       >
      //         <MDTypography variant="caption" color="white">
      //           {info.cell.row.values.status}
      //         </MDTypography>
      //       </MDBox>
      //     );
      //   },
      // },
      {
        Header: "التفاصيل",
        accessor: "desc",
        Cell: (info) => {
          return (
            <MDBox
              alignItems="center"
              width={200}
              sx={{ cursor: "pointer" }}
              onClick={() =>
                clickShowSliderDetailsHandler(info.cell.row.original.id)
              }
            >
              <MDTypography variant="caption" fontWeight="medium">
                {info.cell.row.original.desc?.length > 50
                  ? info.cell.row.original.desc?.slice(0, 50 - 1) + "..."
                  : info.cell.row.original.desc}
              </MDTypography>
            </MDBox>
          );
        },
      },
      {
        Header: t("actions"),
        disableSortBy: true,
        accessor: "",
        Cell: (info) => {
          return (
            <MDBox display="flex" alignItems="center">
              {info.cell.row.original.status === "draft" ? (
                <Tooltip
                  title={t("changeStatus")}
                  onClick={(e) =>
                    updateStatusHandler(e, info.cell.row.original)
                  }
                >
                  <IconButton>
                    <StatusOffIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip
                  title={t("changeStatus")}
                  onClick={(e) =>
                    updateStatusHandler(e, info.cell.row.original)
                  }
                >
                  <IconButton>
                    <StatusOnIcon />
                  </IconButton>
                </Tooltip>
              )}
              <Tooltip
                title={t("deleteItem")}
                onClick={(e) =>
                  clickDeleteHandler(e, info.cell.row.original.id)
                }
              >
                <IconButton>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </MDBox>
          );
        },
      },
    ],
    rows: tableData,
  };

  const clickAddHandler = () => {
    navigate("/websiteManagement/add/slider");
  };

  const getCsvRows = (info) => {
    // let updatedInfo = info.map((row) => {
    //   let csv = {
    //     id: row.id,
    //     date_created: moment(row.date_created).format(format1),
    //     responsible: `${row.responsible_id.first_name} ${
    //       row.responsible_id.last_name ? row.responsible_id.last_name : ""
    //     }`,
    //     pationt: row.pationt_id.first_name,
    //     pationtPhone: row.pationt_id.phone,
    //     clinic: row.clinic_id.name,
    //     smsource: row.source_id.name,
    //     details: row.details,
    //     gclid: row.gclid,
    //     fbclid: row.fbclid,
    //     campaign: row.campaign,
    //     source: row.source,
    //     medium: row.medium,
    //     term: row.term,
    //     content: row.content,
    //     status: row.status_id.name,
    //   };
    //   const keys = Object.keys(csv);
    //   let csvObj = {};
    //   keys.forEach((element) => {
    //     csvObj[t(element)] = csv[element];
    //   });
    //   return csvObj;
    // });
    // return updatedInfo;
    return {};
  };

  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      {notification.value && (
        <MDAlert color="info" my="20px">
          <MDTypography variant="body2" color="white">
            {notification.text}
          </MDTypography>
        </MDAlert>
      )}
      <MDBox pb={3}>
        <MDBox mb={3}>
          <Card>
            <MDBox
              p={3}
              lineHeight={1}
              display="flex"
              justifyContent="space-between"
            >
              <MDTypography variant="h5" fontWeight="medium">
                {t("sliders")}
              </MDTypography>
              <MDBox display="flex">
                <MDButton
                  variant="gradient"
                  color="dark"
                  size="small"
                  type="submit"
                  onClick={clickAddHandler}
                  mx={1}
                >
                  + {t("addItem")}
                </MDButton>
                {/* <MDButton
                  variant="gradient"
                  color="dark"
                  size="small"
                  type="submit"
                  onClick={() => setShowFilter(!showFilter)}
                  sx={{ mx: 1 }}
                >
                  {t("filter")}
                </MDButton> */}

                {/* <MDBox sx={{ cursor: "pointer" }}>
                    <CsvDownloadButton
                      sx={{ cursor: "pointer" }}
                      data={getCsvRows(data)}
                      style={{
                        backgroundColor: "rgb(37 37 39)",
                        color: "#fff", 
                        fontSize: "15px",
                        fontWeight: "bold",
                        padding: "6px 24px",
                        borderRadius: "6px",
                      }}
                      delimiter=","
                    >
                      {t("export")}
                    </CsvDownloadButton>
                  </MDBox> */}
              </MDBox>
            </MDBox>
            {/* {showFilter && (
              <FilterProject
                setFilterUrl={setFilterUrl}
                filterUrl={filterUrl}
              />
            )} */}
            <MDBox mt={1.5} px={3}></MDBox>
            <DataTable
              setLimitChange={setLimitChange}
              table={dataTableData}
              meta={meta}
              setPage={setPage}
              setSortVal={setSortVal}
              pageNum={page}
              isSorted={false}
            />
          </Card>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Items;
