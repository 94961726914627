/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import FormField from "../FormField";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// NewUser page components
import { Autocomplete } from "@mui/material";
import { useState } from "react";
import MDInput from "components/MDInput";

function Other({ formData, lang, authors = [], tags = [] }) {
  const { formField, values, errors, touched, setFieldValue, setFieldTouched } =
    formData;
  const { author_id, tags_id, date } = formField;
  const { author_id: author_idV, tags_id: tags_idV, date: dateV } = values;
  const [dateVal, setDate] = useState(dateV);

  const autoChangeHandler = (name, newValue) => {
    console.log(name, newValue);
    
    setFieldValue(name, newValue);
    setFieldTouched(name, newValue);
  };

  return (
    <MDBox>
      <MDTypography variant="h5" fontWeight="bold">
        {lang?.name}
      </MDTypography>
      <MDBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <Autocomplete
              multiple
              options={tags}
              name={tags_id.name}
              renderOption={(props, option) => (
                <li {...props} key={option.id}>
                  {option.data ? option.data.name : option.name}
                </li>
              )}
              getOptionLabel={(option) => {
                if (option.data) {
                  if (option.data.name) return option.data.name;
                } else {
                  if (option) {
                    if (option.name) return option.name;
                  }
                }
                return "";
              }}
              isOptionEqualToValue={(option, item) =>
                option === item || option.id === item.id
              }
              renderInput={(params) => (
                <MDInput
                  variant="outlined"
                  {...params}
                  //variant="standard"
                  label={tags_id.label}
                  error={errors.tags_id && touched.tags_id}
                  success={tags_idV?.length > 0 && !errors.tags_id}
                />
              )}
              // onChange={autoImplCountryIdChangeHandler}
              onChange={(e, v) => autoChangeHandler(tags_id.name, v)}
              value={tags_idV}
            />
          </Grid>
          <Grid item xs={4}>
            <Autocomplete
              options={authors}
              name={author_id.name}
              renderOption={(props, option) => (
                <li {...props} key={option.id}>
                  {option.data ? option.data.name : option.name}
                </li>
              )}
              getOptionLabel={(option) => {
                if (option.data) {
                  if (option.data.name) return option.data.name;
                } else {
                  if (option) {
                    if (option.name) return option.name;
                  }
                }
                return "";
              }}
              isOptionEqualToValue={(option, item) =>
                option === item || option.id === item.id
              }
              variant="outlined"
              renderInput={(params) => (
                <MDInput
                  {...params}
                  //variant="standard"
                  label={author_id.label}
                  error={errors.author_id && touched.author_id}
                  success={author_idV?.length > 0 && !errors.author_id}
                />
              )}
              onChange={(e, v) => autoChangeHandler(author_id.name, v)}
              value={author_idV}
            />
          </Grid>
          <Grid item xs={4}>
            <FormField
              type="date"
              label="التاريخ"
              name={date.name}
              value={dateVal}
              onChange={(e, v) => {
                autoChangeHandler(date.name, e.target.value);
                setDate(e.target.value);
              }}
            />
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

// typechecking props for Other
Other.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default Other;
